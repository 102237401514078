import React, { useState, useEffect } from 'react';
import '../App.css';
import './data-management.css';
import CSS from 'csstype';
import { AccessToken } from '../types';
import { useTranslation } from "react-i18next";

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function DataManagementNamespaceList( props:	{
								changeNamespace: any,
								placeholderShown: boolean,
								value: string,
								dataManagementToken: AccessToken
								} )
{

	// translation function
	const { t } = useTranslation();
	
	const [sNamespaceList, setNamespaceList] = useState< string[] >( [] );
	
  	useEffect	( () =>
		  	{

				// only proceed if we have a data-management access token.
				if (props.dataManagementToken.access_token !== "")
				{
				
					// load the namespace data from the data-management API.
					loadNamespaces();
				
				}
				
			}, []
			);

	//	------------------------------------------------------------
	//
	//	An asynchronous function that loads the namespace data
	//	from the data-management API.
	//
	//	------------------------------------------------------------
  	
  	async function loadNamespaces()
  	{
									
		//var urlCommand: string =	'http://localhost:8080/v1/data_management/list_namespaces?';
		var urlCommand: string =	'https://gateway.srcdev.skao.int/tannet-api/v1/data_management/list_namespaces?';
		
		// add site-capabilities access token.
		urlCommand = urlCommand +	'token=' + props.dataManagementToken.access_token;

		try
		{
		
			const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}} );

			// Return code 200 means the API has run.
			if (apiResult.status === 200)
			{

				const returnedJson = await apiResult.json();

				// get namespaces list.
				var namespaceList: any = [];
				if (returnedJson.namespaces !== undefined)
					namespaceList = returnedJson.namespaces;

				// update the state with the list of returned sites.
				setNamespaceList( namespaceList );
			
			}
			
		}
		catch (e)
		{
		}
  	
  	} // loadNamespaces

	//	------------------------------------------------------------
	//
	//	Handler for select box onChange event.
	//
	//	------------------------------------------------------------
	
	const onChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const value = event.target.value;
		
		// raise an onChange event.
		props.changeNamespace( {namespace: value} );
				
	} // onChange
	
	return	(
	    	
	    	<select	name = "lstNamespace"
	    			className = "listbox"
	    			multiple = {false}
	    			onChange = {onChange}
	    			data-placeholder-shown = {props.placeholderShown ? "T" : "F"}>
	    		<option hidden selected value = "">{t("Select namespace")}</option>
	    		{
	    			sNamespaceList.map
	    			(
	    				item =>
	    				(
	    					<option label = {item} value = {item} selected = {item === props.value}> {item} </option>
	    				)
	    			)
	    		}
		</select> 
		
		)

} // DataManagementNamespaceList
