import React, { useState, useRef, Ref, useEffect } from 'react';
import '../App.css';
import CSS from 'csstype';

// images
import closeIcon from '../icons/close.254.png';
import searchLocationIcon from '../icons/search-location.512.png';
import searchCatalogIcon from '../icons/search-catalog.512.png';
import searchCatalogDisabledIcon from '../icons/search-catalog-bw.512.png';

// classes
import { SelectedRowsType } from './search-catalog-results';
import SearchCatalogResults from './search-catalog-results';
import SearchCatalogForm from './search-catalog-form';
import ToolSmall from '../tools/tool-small';
import ToolButton from '../tools/tool-button';
import { HideDropdownMenu } from '../functions';
import { AccessToken } from '../types';
import { Aladin } from '../visualisation/aladin';
import { moveAladinView } from '../visualisation/aladin';
import { useTranslation } from "react-i18next";

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

export type SearchResultsType =
{
	id: string;
	dataset: string;
	obs_publisher_did: string[];
	ra: number;
	dec: number;
	fov: number;
	dataProductType: string | undefined;
}

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	Displays a row of tabbed buttons, only one of which can be selected
//	at any one time.
//
//	--------------------------------------------------------------------------

function ResultsButtons( args:	{
					buttonHandler: any,
					searchCatalogTab: string,
					searchResults: SearchResultsType[],
					firstItem: number,
					lastItem: number,
					tabWidth: number
					} ): JSX.Element
{
	//translation function
	const { t, i18n } = useTranslation();
	
	return	<>{
			args.searchResults.filter
			(
				(tab, index) => index >= args.firstItem && index <= args.lastItem
			).map
			(
				(tab, index) =>
				(
					<ToolSmall	name = {"btnResults" + tab.id}
							closeid = {"btnCloseResults" + tab.id}
							text = {t("Results", {number: tab.id} )}
							withClose = {true}
							onClick = {args.buttonHandler}
							selected = {args.searchCatalogTab === tab.id}
							left = {false}
							tabWidth = {args.tabWidth} />
				)
			)
		}</>

} // ResultsButtons

//	--------------------------------------------------------------------------
//
//	Displays a collection of SearchCatalogResults components, only one
//	of which is visible at any one time.
//
//	--------------------------------------------------------------------------

function ResultsTabs( args:	{
				searchCatalogTab: string,
				searchResults: SearchResultsType[],
				rowCheckedHandler: any,
				selectedRows: SelectedRowsType[],
				accessToken: AccessToken,
				viewInAladin: any,
				selectedID: { id: string, ra: number, dec: number }[],
				highlightedID: string,
				setHighlightedID: any,
				initiateDataManagementSearchEvent: any
				} ): JSX.Element
{

	return	<>{
			args.searchResults.map
			(
				(tab) =>
				(
					<SearchCatalogResults	tabID = {tab.id}
								key = {"tab_" + tab.id}
								dataset = {tab.dataset}
								ra = {tab.ra}
								dec = {tab.dec}
								fov = {tab.fov}
								obs_publisher_did = {tab.obs_publisher_did}
								dataProductType = {tab.dataProductType}
								rowCheckedHandler = {args.rowCheckedHandler}
								selectedRows = {args.selectedRows.find( element => element.tabID === tab.id )}
								hidden = {args.searchCatalogTab !== tab.id}
								accessToken = {args.accessToken}
								viewInAladin = {args.viewInAladin}
								selectedID = {args.selectedID}
								highlightedID = {args.highlightedID}
								setHighlightedID = {args.setHighlightedID}
								initiateDataManagementSearchEvent = {args.initiateDataManagementSearchEvent} />
				)
			)
		}</>

} // ResultsTabs

// ------------------------------------------------------------
//
//	Display the search results tabs at the top of the
//	screen.
//
// ------------------------------------------------------------

function TabBar( args:	{
			searchResults: SearchResultsType[],
			searchCatalogTab: string,
			onClickHandler: any,
			tabDropdownDisplayed: boolean,
			setTabDropdownDisplayed: any
			} )
{

	// translation function
	const { t } = useTranslation();
 
	// maximum number of tabs that can be displayed along the tab bar.
	const [sMaxTabs, setMaxTabs] = useState<number>( -1 );
	const [sTabWidth, setTabWidth] = useState<number>( 131 );
	const [sDropdownWidth, setDropdownWidth] = useState<number>( 40 );
	const [sFirstTab, setFirstTab] = useState<number>( 0 );
	
	// handle changes in size to the tab bar.
	const resizeTabBar =	React.useRef<ResizeObserver>
				(
					new ResizeObserver
					(
						(entries:ResizeObserverEntry[]) =>
						{
						
							// recalculate the number of tabs that we can display along the top. any other tabs
							// must be placed in a drop-down menu.
							if (entries[0].contentRect.width > 0)
							{
		  						const maxTabs: number = Math.floor( (entries[0].contentRect.width - 322) / 131 );
		  						const tabWidth: number = Math.floor( (entries[0].contentRect.width - 322) / maxTabs );
		  						const dropdownWidth: number = entries[0].contentRect.width - 282 - (maxTabs * tabWidth);
								setMaxTabs( maxTabs );
								setTabWidth( tabWidth );
								setDropdownWidth( dropdownWidth );
							}
							
						}
					)
				);

	// create a reference to the HTML Div element that contains the tab bar, so that we can monitor for changes in size.
	const tabButtons =	React.useCallback
				(
					(container: HTMLDivElement) =>
					{
						
						// check that this reference has actually been assigned to an element, and switch on observing.
    						if (container !== null)
        						resizeTabBar.current.observe( container );
    							
						// When element is unmounted, ref callback is called with a null argument
						// => best time to cleanup the observer
    						else
        						if (resizeTabBar.current)
            							resizeTabBar.current.disconnect();
    							
					}, [resizeTabBar.current]
				);
							
	// check if we need to hide the drop-down menu.
	if (args.tabDropdownDisplayed === true && sMaxTabs >= args.searchResults.length)
		args.setTabDropdownDisplayed( false );
		
	// change the first tab displayed in the list to ensure that the selected tab is displayed.
	{
		let index = args.searchResults.findIndex( element => element.id === args.searchCatalogTab );
		var firstTab: number = sFirstTab;
		if (index > -1)
		{
			if (index < firstTab)
				firstTab = index;
			if (index >= firstTab + sMaxTabs)
				firstTab = index - sMaxTabs + 1;
		}
		if (args.searchResults.length - firstTab - 1 < sMaxTabs)
			firstTab = args.searchResults.length - sMaxTabs;
		if (firstTab < 0)
			firstTab = 0;
		if (firstTab != sFirstTab)
			setFirstTab( firstTab );
	}

	return	(
	
    		/* we have a header row containing the tabs for the search form and (multiple) results */
    		<div ref = {tabButtons} className = "search-tab-bar">
    		
    			<div className = "flex-10px"></div>
    			
    			{/* first we have the dropdown button for displayed extra tabs */}
		    	<ToolSmall	name = "btnTabDropdown"
		    			text = "&#9662;"
		    			onClick = {args.onClickHandler}
		    			left = {true}
		    			displayDropdown = {true}
		    			tabWidth = {sDropdownWidth}
		    			disabled = {sMaxTabs >= args.searchResults.length || sMaxTabs === -1}
		    			dropdown =	{
				    			<TabDropdownMenu	sTabDropdownDisplayed = {args.tabDropdownDisplayed}
				    						setTabDropdownDisplayed = {args.setTabDropdownDisplayed}
				    						items = {args.searchResults}
				    						firstItem = {sFirstTab}
				    						lastItem = {sFirstTab + sMaxTabs - 1}
				    						buttonHandler = {args.onClickHandler} />
		    					} />
		    	
		    	{/* and then we have the results tabs */}
			<ResultsButtons	buttonHandler = {args.onClickHandler}
						searchCatalogTab = {args.searchCatalogTab}
						searchResults = {args.searchResults}
						firstItem = {sFirstTab}
						lastItem = {sFirstTab + sMaxTabs - 1}
						tabWidth = {sTabWidth} />
    			
    			{/* and then we have the empty, expanding tab in the middle */}
		    	<ToolSmall	name = ""
		    			text = ""
		    			onClick = {args.onClickHandler}
		    			empty = {true}
		    			left = {false}
		    			hidden = {sMaxTabs <= args.searchResults.length} />
    			
		    	{/* and then we have the retrieval tab */}
			<ToolSmall	name = "btnDataRetrieve"
					text = {t( "Retrieve data" )}
					withNumber = {true}
					count = {0}
					onClick = {args.onClickHandler}
					selected = {args.searchCatalogTab === "retrieve"}
					right = {true}
					disabled = {args.searchResults.length > 0 ? false : true} />
					
    			<div className = "flex-10px"></div>
    			
    		</div>
	
		)

} // TabBar

// ------------------------------------------------------------
//
//	This dropdown mimics a droplist listbox, but
//	allows an image to be displayed next to each list item.
//
// ------------------------------------------------------------

function TabDropdownMenu( args:	{
					sTabDropdownDisplayed: boolean,
					setTabDropdownDisplayed: any,
					items: SearchResultsType[],
					firstItem: number,
					lastItem: number,
					buttonHandler: any
					} )
{

	// function that monitors for mouse clicks. we need to add {ref} to the DIV element of the dropdown menu.
	const { ref } = HideDropdownMenu(	{
						sDropdownDisplayed: args.sTabDropdownDisplayed,
						setDropdownDisplayed: args.setTabDropdownDisplayed
						} );
												
	//	------------------------------------------------------------
	//
	//	A HTML component that renders a single menu item on the
	//	dropdown menu.
	//
	//	------------------------------------------------------------

	function MenuItem( args: { name: string, text: string, closeid: string, onClick: any } )
	{

		return	(
		
			<button name = {args.name} className = "dropdown-list-item" onClick = {args.onClick}>
				<div className = "dropdown-list-item-text" data-align="L">{args.text}</div>
				<button name = {args.closeid} type = "button" className = "button-close" onClick = {args.onClick}><div className="button-close-icon-container"><img src = {closeIcon} alt = "" width = "20" height = "20" /></div></button>
			</button>
		
			)

	} // MenuItem

	return	(

		<div	ref = {ref}
			style =	{{
					display: (args.sTabDropdownDisplayed === true ? 'block' : 'none'),
					position: 'absolute',
					top: '100%',
					left: '0%',
					border: 'none',
					zIndex: '9'
					}} >
				
			<div className = "language-dropdown-menu">
				{
				args.items.filter
				(
					(item, index) => index < args.firstItem || index > args.lastItem
				).map
				(
					(item, index) =>
					(
						MenuItem(	{
								name: 'btnResults' + item.id,
								text: 'Results ' + item.id,
								closeid: "btnCloseResults" + item.id,
								onClick: args.buttonHandler
								} )
					)
				)
				}
			</div>
			
		</div>
			
		)

} // TabDropdownMenu

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function SearchCatalog( props:	{
						accessToken: AccessToken,
						viewInAladin: any,
						searchPosition:	{
									id: string,
									ra: number,
									dec: number
									}[],
						setSearchPosition: any,
						updateState: any,
						tabVisible: boolean,
						initiateDataManagementSearchEvent: any
						} )
{

	// translation function
	const { t } = useTranslation();

	//	-------------------------------------------------
	//
	//	state variables
	//
	//	-------------------------------------------------

	// maintain the state for the selected rows on the results tabs.
  	const [sSelectedRows, setSelectedRows] = useState<SelectedRowsType[]>( [] );

  	// search catalog tool:
  	const [sSearchCatalogTab, setSearchCatalogTab] = useState<string>( '' );
  	const [sSearchResultsNextID, setSearchResultsNextID] = useState<number>( 0 );
  	const [sSearchResults, setSearchResults] = useState<SearchResultsType[]>( [] );
	
	// boolean to hold whether the tab drop-down menu is displayed.
	const [sTabDropdownDisplayed, setTabDropdownDisplayed] = useState<boolean>( false );
	
  	// loading component.
  	const [sLoadingComponent, setLoadingComponent] = useState<boolean>( true );
  	
  	// current Aladin instance.
  	const [sAladin, setAladin] = useState<any>( null );
  	const [sAladinMaximised, setAladinMaximised] = useState<boolean>( true );
  	const [sAladinRefresh, setAladinRefresh] = useState<number>( 0 );
  	
  	// these state values are set if the input boxes on the search filter are changed. they are reset to undefined after Aladin is updated.
  	const [sSearchPosition, setSearchPosition] = useState< { ra: number, dec: number } | undefined >( undefined );
  	const [sSearchFOV, setSearchFOV] = useState<number | undefined>( undefined );
  	
  	// and these state values are set if the position in Aladin is changed. they are reset to undefined after the search filter input boxes are updated.
  	const [sAladinPosition, setAladinPosition] = useState< { ra: number, dec: number } | undefined >( undefined );
  	const [sAladinFOV, setAladinFOV] = useState<number | undefined>( undefined );
  	
  	// footprint has been selected.
  	const [sSelectedID, setSelectedID] = useState< {	id: string,
  								ra: number,
  								dec: number }[] >( [] );
  	
  	// footprint is highlighted.
  	const [sHighlightedID, setHighlightedID] = useState<string>( '' );

	//	-------------------------------------------------
	//
	//	function that adds a new search results tab when the user clicks on
	//	search on the search parameters form.
	//
	//	-------------------------------------------------
  	
  	const addSearchResultsTab = function( args:	{
  							datasetValue: string,
  							raValue: number,
  							decValue: number,
  							radiusValue: number,
  							obsPublisherDidValue: string[],
  							dataProductType: string | undefined
  							} )
  	{
  	
  		// store the number of tabs.
		var items: number = sSearchResults.length
			
		// use the next available ID to number the search results.
		setSearchResults( prevParams => [ ...prevParams,	{
									id: sSearchResultsNextID.toString(),
									dataset: args.datasetValue,
									ra: args.raValue,
									dec: args.decValue,
									fov: args.radiusValue,
									obs_publisher_did: args.obsPublisherDidValue,
									dataProductType: args.dataProductType
									}, ] );
		
		// set the current tab to the new results tab.
		setSearchCatalogTab( sSearchResultsNextID.toString() );
		
		// increment the index
		setSearchResultsNextID( sSearchResultsNextID + 1 );
		
		// restart Aladin at this position.
		moveAladinView(	{
					aladin: sAladin,
					ra: args.raValue,
					dec: args.decValue,
					fov: args.radiusValue,
					selectedID: args.obsPublisherDidValue,
					setSelectedID: setSelectedID,
					loadCatalog: true
					} );
						
		// the zoomChanged event in Aladin is not triggered when we update the FOV using setFOV(). it's only triggered by a user zoom.
		// so in order to update the FOV in the Filter window we need to set it here.
		setAladinFOV( args.radiusValue );
  	
  	} // addSearchResultsTab

	//	-------------------------------------------------
	//
	//	function that removes a search results tab when the user
	//	clicks on the X.
	//
	//	-------------------------------------------------
	
  	const closeSearchResultsTab = function( args: { id: string } )
  	{
			
		// find this ID within the array of results tabs.
		let index = sSearchResults.findIndex( element => element.id === args.id );
		
		// if we found it then remove it.
		if (index > -1)
		{
			let tmp = sSearchResults.slice();	// we use slice() so that the array is a different object. this FORCES rerendering of components that use sSearchResults.
			tmp.splice( index, 1 );
			setSearchResults( tmp );
			setSearchCatalogTab( "" );
		}
  	
  	} // closeSearchResultsTab

	//	------------------------------------------------------------
	//
	//	Handler for button onClick events.
	//
	//	------------------------------------------------------------
	
	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
	{

		const button: HTMLButtonElement = event.currentTarget;

		// set which tab is selected on the Search Catalog form. If we clicked on a results tab, then store the index of the tab.
		if (button.name === "btnDataRetrieve")
			updateState( { searchCatalogTab: "retrieve" } );
		if (button.name === "btnTabDropdown")
			setTabDropdownDisplayed( !sTabDropdownDisplayed );
			
		// if we've chosen to search the current Aladin FOV, then add a new search results tab.
		if (button.name === "searchLocation")
		{
	
			// get the current position and FOV.
			const position: Float64Array = sAladin.getRaDec();
			const fov: Float64Array = sAladin.getFov();

			addSearchResultsTab(	{
						datasetValue: '',
						raValue: position[ 0 ], 
						decValue: position[ 1 ],
						radiusValue: fov[ 0 ],
						obsPublisherDidValue: [],
						dataProductType: undefined
						} );
						
		}
			
		// if we've chosen to search for the selected objects in the TAP catalog, then compute the average RA and DEC.
		if (button.name === "searchSelectedObject" && sSelectedID.length > 0 )
			props.setSearchPosition( sSelectedID );
			
		if (button.name === "minimiseAladin" )
			setAladinMaximised( false );
		if (button.name === "maximiseAladin" )
			setAladinMaximised( true );
		
		// if we clicked on a tab, then change the currently selected tab.
		if (button.name.length > 10)
			if (button.name.slice( 0, 10 ) === "btnResults")
			{
			
				const newTab: string = button.name.slice( 10 - button.name.length );
			
				// update the currently-selected tab.
				updateState( { searchCatalogTab: newTab } );
				
				// ensure the tab dropdown is closed.
				setTabDropdownDisplayed( false );
			
				// find the ID of the selected tab within the array of results tabs.
				let index = sSearchResults.findIndex( element => element.id === newTab );
				
				// if we found it then get the ra, dec, and FOV.
				var ra: number = 0.0;
				var dec: number = 0.0;
				var fov: number = 5.0;
				if (index > -1)
				{
					ra = sSearchResults[ index ].ra;
					dec = sSearchResults[ index ].dec;
					fov = sSearchResults[ index ].fov;
				}
				
				// reload Aladin at this position.
				moveAladinView(	{
							aladin: sAladin,
							ra: ra,
							dec: dec,
							fov: fov,
							selectedID: [],
							setSelectedID: undefined,
							loadCatalog: true
							} );
						
				// the zoomChanged event in Aladin is not triggered when we update the FOV using setFOV(). it's only triggered by a user zoom.
				// so in order to update the FOV in the Filter window we need to set it here.
				setAladinFOV( fov );
				
				// clear the highlighted object, and the selected object.
				setHighlightedID( '' );
				setSelectedID( [] );
				
			}
		
		// if we click to close a results tab then remove this id from the list.
		if (button.name.length > 15)
			if (button.name.slice( 0, 15 ) === "btnCloseResults")
			{
			
				let id = button.name.slice( 15 - button.name.length );

				// stop the event propogating to the tab itself.				
				event.stopPropagation();

				// close this search results tab.
				closeSearchResultsTab( { id: id } );
				
			}
				
	} // onClickHandler

	//	-------------------------------------------------
	//
	//	function that will update the state of the selected rows when a row is ticked or unticked.
	//
	//	-------------------------------------------------
  	
  	function rowCheckedHandler( args: { tabID: string, dataTypeIndex: number, row: number, ticked: boolean } )
  	{
  	
  		// check if we've got an array element with this tab ID.
  		let tab: SelectedRowsType;
		let tabIndex = sSelectedRows.findIndex( element => element.tabID === args.tabID );
  	
  		// if we found the existing element, get a copy and remove it.	
  		if (tabIndex > -1)
  		{
  			tab = sSelectedRows[ tabIndex ];
  			let tmp = sSelectedRows;
  			tmp.splice( tabIndex, 1 );
  			setSelectedRows( tmp );
  		}
  		else
  		
	  		// if there is no existing element, add one.
  			tab = { tabID: args.tabID, selectedRows: [] };
  			
  		// look to see if the selected data type exists in the array.
  		let dataType: { dataTypeIndex: number, selectedRows: number[] };
  		let arrayIndex = tab.selectedRows.findIndex( element => element.dataTypeIndex === args.dataTypeIndex );
  		
  		// if we found the existing data type, get a copy and remove it.
  		if (arrayIndex > -1)
  		{
  			dataType = tab.selectedRows[ arrayIndex ];
  			tab.selectedRows.splice( arrayIndex, 1 );
  		}
  		else
  		
  			// if there is no existing element, add one.
  			dataType = { dataTypeIndex: args.dataTypeIndex, selectedRows: [] };
  		
  		// look to see if this row exists in the array.
  		let rowIndex = dataType.selectedRows.findIndex( element => element === args.row );
  		
  		// if it doesn't exist and the row has been ticked, add the row number.
  		if (rowIndex === -1 && args.ticked === true)
  			dataType.selectedRows.push( args.row );
  		
  		// if it does exist and the rows has been unticked, remove the row number.
  		if (rowIndex > -1 && args.ticked === false)
			dataType.selectedRows.splice( rowIndex, 1 );
			
		// add the data type to the tab array.
		tab.selectedRows.push( dataType );
			
		// add the tab to the state array.
		setSelectedRows( prevTabs => [ ...prevTabs, tab, ] );
  	
  	} // rowCheckedHandler

	//	-------------------------------------------------
	//
	//	function that updates the state of variables
	//	held at this level or above.
	//
	//	-------------------------------------------------
  	
  	function updateState( args: { searchCatalogTab?: string } )
  	{
  	
  		// update the tab selected on the search catalog page.
  		if (args.searchCatalogTab !== undefined)
  			setSearchCatalogTab( args.searchCatalogTab );
  	
  	} // updateState

	//	-------------------------------------------------
	//
	//	component code
	//
	//	-------------------------------------------------
	
	// check if the required publisher did list exists on one of our tabs.
	if (props.searchPosition.length > 0)
	{
			
		// find this ID within the array of results tabs.
		var index: number = -1;
		for ( var i = 0; i < sSearchResults.length; i++ )
		{
		
			var match: boolean = true;
			if (sSearchResults[ i ].obs_publisher_did.length === props.searchPosition.length && props.searchPosition.length > 0)
			{
				for ( var j = 0; j < props.searchPosition.length; j++ )
					if (sSearchResults[ i ].obs_publisher_did[ j ] !== props.searchPosition[ j ].id)
						match = false;
			}
			else
				match = false;
				
			// did we find a tab with this set of IDs ?
			if (match === true)
				index = i;
				
		}

		// if we didn't find it then we need to add it.
		if (index === -1)
		{
		
			// compute average RA and DEC, and build list of IDs.
			var ra: number = 0.0;
			var dec: number = 0.0;
			var id: string[] = [];
			for ( var i = 0; i < props.searchPosition.length; i++ )
			{
				ra = ra + props.searchPosition[ i ].ra;
				dec = dec + props.searchPosition[ i ].dec;
				id.push( props.searchPosition[ i ].id );
			}
			if (props.searchPosition.length > 0)
			{
				ra = ra / props.searchPosition.length;
				dec = dec / props.searchPosition.length;
			}
		
			addSearchResultsTab(	{
						datasetValue: '',
						raValue: ra,
						decValue: dec,
						radiusValue: 10.0,
						obsPublisherDidValue: id,
						dataProductType: undefined
						} );
						
		}
		else
		
			// if we DID find it then we need to make sure that this tab is highlighted.
			if (sSearchCatalogTab !== sSearchResults[ index ].id )
				updateState( { searchCatalogTab: sSearchResults[ index ].id } );
						
		// reset the state of the parent object so we don't add this tab again.
		props.updateState( { resetSearchPosition: true } );
	
	}

	return	(
			    	
	    	<div className = "search-catalog">
	    	
	    		<div	style = {{ flex: '0 0', display: 'flex', flexDirection: 'column', height: '100%' }}>
				<SearchCatalogForm	addSearchResultsTab = {addSearchResultsTab}
							tabVisible = {props.tabVisible}
							searchPosition = {sSearchPosition}
							setSearchPosition = {setSearchPosition}
							searchFOV = {sSearchFOV}
							setSearchFOV = {setSearchFOV}
							aladinPosition = {sAladinPosition}
							setAladinPosition = {setAladinPosition}
							aladinFOV = {sAladinFOV}
							setAladinFOV = {setAladinFOV} />
				<div style = {{ flex: '0 0 10px' }} />
			    	<button	className = "minimised-filter"
			    			name = "maximiseAladin"
			    			type = "button"
			    			title = "Maximise Aladin"
			    			onClick = {onClickHandler}
			    			data-maximised = {sAladinMaximised === true ? "T" : "F"} >
					<div className = "flex-15px"></div>
					<div className = "maximise">&raquo;</div>
					<div className = "flex-row">
						<div className = "rotated-text-box">Aladin Lite</div>
				    	</div>
			    	</button>
				<div style = {{ flex: '1 1' }} />
			</div>
			
			<div style = {{ display: 'flex', flexDirection: 'column', flex: '1 1', height: '100%', width: '0px', marginRight: '10px' }} >
				
				<div className = "search-catalog-results" style = {{ flex: '1 1', display: sLoadingComponent === true || sAladinMaximised === false ? 'none' : 'flex', flexDirection: 'row', width: '100%', padding: '5px 0px 5px 0px' }}>
					<div style = {{ flex: '0 0 10px' }}></div>
					<Aladin	divElementID = "aladin-lite-div-search-data"
							aladin = {sAladin}
							setAladin = {setAladin}
							selectedID = {sSelectedID}
							setSelectedID = {setSelectedID}
							highlightedID = {sHighlightedID}
							setHighlightedID = {setHighlightedID}
							setAladinRefresh = {setAladinRefresh}
							setAladinPosition = {setAladinPosition}
							setAladinFOV = {setAladinFOV}
							updatePosition = {sSearchPosition}
							setUpdatePosition = {setSearchPosition}
							updateFOV = {sSearchFOV}
							setUpdateFOV = {setSearchFOV} 
							setLoadingComponent = {setLoadingComponent}
							running = {true} />
					<div style = {{ flex: '0 0 5px' }}></div>
					<div style = {{ display: 'flex', flexDirection: 'column', flex: '0 0 40px', height: '100%' }}>
			    			<button	className = "minimise"
			    					name = "minimiseAladin"
			    					type = "button"
			    					title = "Minimise Aladin"
			    					onClick = {onClickHandler}>&laquo;</button>
			    			<div style = {{ flex: '1 1' }} />
			    			<button	name = "searchLocation"
			    					type = "button"
			    					title = "Search at location"
			    					style = {{display: 'flex', padding: '0px 0px 0px 0px', flex: '0 0 40px', cursor: 'pointer', border: 'none', width: '40px'}}
			    					onClick = {onClickHandler} >
			    				<img src = {searchLocationIcon} alt = "" width = "40" />
			    			</button>
			    			<div style = {{ flex: '0 0 10px' }} />
			    			<button	name = "searchSelectedObject"
			    					type = "button"
			    					title = "Search selected object(s)"
			    					style = {{display: 'flex', padding: '0px 0px 0px 0px', flex: '0 0 40px', cursor: 'pointer', border: 'none', width: '40px'}}
			    					onClick = {sSelectedID.length > 0 ? onClickHandler : undefined} >
			    				<img src = {sSelectedID.length > 0 ? searchCatalogIcon : searchCatalogDisabledIcon} alt = "" width = "40" />
			    			</button>
					</div>
					<div style = {{ flex: '0 0 10px' }}></div>
				</div>
				
				<div style = {{ flex: '0 0 5px' }}></div>
	    	
		    		<div className = "search-catalog-results" >
		    	
			    		{/* we have a header row containing the tabs for the search form and (multiple) results */}
			    		<TabBar	searchResults = {sSearchResults}
			    				searchCatalogTab = {sSearchCatalogTab}
			    				onClickHandler = {onClickHandler}
			    				tabDropdownDisplayed = {sTabDropdownDisplayed}
			    				setTabDropdownDisplayed = {setTabDropdownDisplayed} />
			    				
			    		{/* display all the results tab pages (only one will be visible at any one time) */}
					<ResultsTabs	searchCatalogTab = {sSearchCatalogTab}
							searchResults = {sSearchResults}
							rowCheckedHandler = {rowCheckedHandler}
							selectedRows = {sSelectedRows}
							accessToken = {props.accessToken}
							viewInAladin = {props.viewInAladin}
							selectedID = {sSelectedID}
							highlightedID = {sHighlightedID}
							setHighlightedID = {setHighlightedID}
							initiateDataManagementSearchEvent = {props.initiateDataManagementSearchEvent} />
					
				</div>
			
			</div>
			    	    	
	    	</div>
			    	
		)

} // SearchCatalog
