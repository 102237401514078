import React, { useState, useEffect } from 'react';
import '../App.css';
import './data-management.css';
import CSS from 'csstype';
import '../tools/search-results/search-results.css';
import { useTranslation } from "react-i18next";

// icons
import gearsIcon from '../icons/gears.gif';

// types
import { AccessToken } from '../types';
import { JobItemType } from '../types';
import { JobType } from '../types';

// types relating to tasks.
import { TaskType } from '../tasks';
import { CurrentTask } from '../tasks';

// classes
import ToolButton from '../tools/tool-button';
import { CheckBox } from '../tools/controls';
import DataManagementTable from './data-management-table';
import DataManagementNamespaceList from './data-management-namespace-list';
import SearchResultsFooter from '../tools/search-results/search-results-footer';
import { DisplayFileSize } from '../functions';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	Displays details of the selected file(s)
//
//	------------------------------------------------------------

function DetailsPane( props:	{
				dataManagementToken: AccessToken,
				selectedItems:	{
						namespace: string,
						name: string,
						size: number
						}[],
				renewTokens: any,
				storageAreas:	{
						site: string,
						storage_areas:	{
								associated_storage_id: string,
								storage_id: string,
								storage_type: string,
								relative_path: string,
								identifier: string
								}[]
						}[]
				} )
{
	
	// storage locations where the data is located.
	const [sLocations, setLocations] = useState<	{
							identifier: string,
							associated_storage_area_id: string,
							replicas: string[]
							}[] >( [] );
							
	const [sRetrievingLocations, setRetrievingLocations] = useState< boolean >( true );

	//	------------------------------------------------------------
	//
	//	Kick off some processes once the component has loaded.
	//
	//	------------------------------------------------------------
  	
  	useEffect	( () =>
		  	{
		  	
		  		// set retrieving flag.
		  		setRetrievingLocations( true );
		  		
		  		// get a list of location where this data item is stored.
		  		if (props.selectedItems.length === 1)
			  		getLocations();
			  	else
			  		setLocations( [] );
				
			}, [props.selectedItems]
			);

	//	------------------------------------------------------------
	//
	//	An asynchronous function that gets a list of locations
	//	for a particular data item from the data-management API.
	//
	//	------------------------------------------------------------
  	
  	async function getLocations()
  	{

	
		try
		{

			// only proceed if we have a data-management access token.
			if (props.dataManagementToken.access_token !== "")
			{

				//var urlCommand: string = 'http://localhost:8080/v1/data_management/locate_data?';
				var urlCommand: string = 'https://gateway.srcdev.skao.int/tannet-api/v1/data_management/locate_data?';
				
				// parameters.
				urlCommand = urlCommand +	'data_management_token=' + props.dataManagementToken.access_token + '&' +
								'namespace=' + props.selectedItems[ 0 ].namespace + '&' +
								'name=' + props.selectedItems[ 0 ].name;

				try
				{
					
					const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}} );
					if (apiResult.status === 200)
					{
				
						// get storage locations.
						var locations:	{
								identifier: string,
								associated_storage_area_id: string,
								replicas: string[]
								}[] = [];
					
						const returnedJson = await apiResult.json();
						if (returnedJson.locations !== undefined)
							locations = returnedJson.locations;
						
						// update the state with the list of returned storage areas.
						setLocations( locations );
						
					}
					
					// if the return code is 401 then either the data-management token or the gateway-backend
					// token has expired. we should renew them.
					else if (apiResult.status === 401)
					{
						const currentTask: CurrentTask = { taskType: TaskType.NONE };
						props.renewTokens( { currentTask: currentTask } );
					}
					
					else
					
						// if the return code is 404 then the Rucio DID was not found.
						setLocations( [] );
					
				}
				catch (e)
				{
					console.log( e );
					setLocations( [] );
				}
			
			}
			
      		}
      		catch (e)
      		{
			console.log(e);
			setLocations( [] );
		}
			
		// locations retrieved.
		setRetrievingLocations( false );
  	
  	} // getLocations

	//	------------------------------------------------------------
	//
	//	Loop through the list of storage areas, and get the
	//	site and storage identifier of the storage ID provided.
	//
	//	------------------------------------------------------------
  	
  	function getStorageLocationName( args:	{
  							id: string
  							} )
  	{
  	
  		var storageText: string = '{' + args.id + '}';
  		
  		for ( var siteID = 0; siteID < props.storageAreas.length; siteID++ )
  			for ( var storageAreaID = 0; storageAreaID < props.storageAreas[ siteID ].storage_areas.length; storageAreaID++ )
  			
  				// check if we've matched the ID, and update the text.
  				if (props.storageAreas[ siteID ].storage_areas[ storageAreaID ].storage_id == args.id)
  					storageText = props.storageAreas[ siteID ].site + ' -> ' +
  							props.storageAreas[ siteID ].storage_areas[ storageAreaID ].identifier;
  	
  		// return something.
  		return storageText;
  	
  	} // getStorageLocationName

	// add up the files.
	var totalSize: number = 0.0;
	for ( var i = 0; i < props.selectedItems.length; i++ )
		totalSize = totalSize + props.selectedItems[ i ].size;

	return	(
	
		<div className = "data-management-scrollbox">
			<div>
			{
				(
				props.selectedItems.length === 0 ?
				'' :
				props.selectedItems.length === 1 ?
				props.selectedItems[ 0 ].name + ':' :
				props.selectedItems.length.toString() + ' items selected:'
				) + ' ' + DisplayFileSize( { bytes: totalSize } )
			}
			</div>
			<div style = {{ marginTop: '10px', marginBottom: '10px', display: props.selectedItems.length === 1 ? 'inline' : 'none' }} >
				Currently located at:
			</div>
			<div style = {{  display: props.selectedItems.length === 1 ? 'inline' : 'none' }} >
			{
				sRetrievingLocations === false ?
				sLocations.map	(
							(item, index) =>
							(
							
							<div style = {{ marginLeft: '10px' }} >
							{
								getStorageLocationName( { id: item.associated_storage_area_id } )
							}
							</div>
							
							)
							
						) :
				<div style = {{ marginLeft: '10px' }} >Retrieving.....</div>
			}
			</div>
		</div>
		
		)

} // DetailsPane

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function DataManagement( props:	{
							authToken: AccessToken,
							dataManagementToken: AccessToken,
							siteCapabilitiesToken: AccessToken,
							gatewayBackendToken: AccessToken,
							dataManagementJobs: JobType[],
							renewTokens: any,
							getDataManagementJobs: any,
							taskExecutor: any,
							jobsLoading: boolean,
							tabVisible: boolean,
							initiateSearch: any
							} )
{

	// translation function
	const { t } = useTranslation();

  	// filter values.
  	const [sNamespaceValue, setNamespaceValue] = useState< string >( '' );
  	const [sFilterFilename, setFilterFilename] = useState< string >( '' );
  	
  	// parameter values.
  	const [sParamsLifetime, setParamsLifetime] = useState< string >( '3600' );
  	
  	// current parameters for the results table.
  	const [sTableNamespace, setTableNamespace] = useState< string >( '' );
  	const [sTableFilename, setTableFilename] = useState< string >( '' );
  	const [sTableParamsLifetime, setTableParamsLifetime] = useState< number >( 3600 );
  	const [sTableRenderCount, setTableRenderCount] = useState< number >( 0 );
  	const [sTableDisplayed, setTableDisplayed] = useState< boolean >( false );
  	
  	// pop-up boxes maximised, or not?
  	const [sFilterMaximised, setFilterMaximised] = useState< boolean >( true );
  	const [sParametersMaximised, setParametersMaximised] = useState< boolean >( true );
  	const [sDetailsMaximised, setDetailsMaximised] = useState< boolean >( true );
  	const [sSelectedItems, setSelectedItems] = useState<	{
  								namespace: string,
  								name: string,
  								size: number
  								}[] >( [] );
  	
  	// storage areas.
	const [sStorageAreas, setStorageAreas] = useState<	{
								site: string,
								storage_areas:	{
										associated_storage_id: string,
										storage_id: string,
										storage_type: string,
										relative_path: string,
										identifier: string
										}[]
								}[] >( [] );
  	
  	// data-management jobs.
	const [sJobIDExpanded, setJobIDExpanded] = useState< string[] >( [] );
	const [sJobRenderCount, setJobRenderCount] = useState< number >( 0 );

	//	------------------------------------------------------------
	//
	//	Kick off some processes once the page has loaded.
	//
	//	------------------------------------------------------------
  	
  	useEffect	( () =>
		  	{
		  		
		  		// load the list of storage areas from the site-capabilities API. this list of storage areas will be rebuilt each
		  		// time the site-capabilities token is refreshed.
		  		loadStorageAreas();
		  		
		  		// set the initiate search function so that the parent component can call it.
		  		props.initiateSearch( initiateDMSearch );
				
			}, [props.siteCapabilitiesToken.access_token]
			);

	//	------------------------------------------------------------
	//
	//	a data-management search is initiated, either from the Search
	//	button or from a parent component.
	//
	//	------------------------------------------------------------
			
	function initiateDMSearch( args:	{
						namespace: string,
						filename: string
						} )
	{
	
		console.log( "data management search initiated:" );
		console.log( args.namespace );
		console.log( args.filename );
		
		// filter values.
		setNamespaceValue( args.namespace );
		setFilterFilename( args.filename );
		setTableNamespace( args.namespace );
		setTableFilename( args.filename );
		
		// hide the details panel.
		setSelectedItems( [] );
		
		// run the query.
		setTableDisplayed( true );
		setTableRenderCount( sTableRenderCount + 1 );
		console.log( sTableRenderCount );
	
	} // initiateDMSearch

	//	------------------------------------------------------------
	//
	//	handler for changes to the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  		
  		// update the state for any input boxes that have been changed.
  		if (inputBox.name === 'filename')
  			setFilterFilename( inputBox.value );
  		if (inputBox.name === 'lifetime')
  			setParamsLifetime( inputBox.value );
  	
  	} // inputHandler

	//	------------------------------------------------------------
	//
	//	An asynchronous function that loads a list of storage
	//	areas from the site-capabilities API.
	//
	//	------------------------------------------------------------
  	
  	async function loadStorageAreas()
  	{

	
		try
		{

			// only proceed if we have a site-capabilities access token.
			if (props.siteCapabilitiesToken.access_token !== "")
			{

				//var urlCommand: string = 'http://localhost:8080/v1/site_capabilities/list_storage?';
				var urlCommand: string = 'https://gateway.srcdev.skao.int/tannet-api/v1/site_capabilities/list_storage?';
				
				// token.
				urlCommand = urlCommand +	'token=' + props.siteCapabilitiesToken.access_token;

				try
				{
					
					const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}} );
					if (apiResult.status === 200)
					{
				
						// get storage list.
						var storageList:	{
									site: string,
									storage_areas:	{
											associated_storage_id: string,
											storage_id: string,
											storage_type: string,
											relative_path: string,
											identifier: string
											}[]
									}[] = [];
					
						const returnedJson = await apiResult.json();
						if (returnedJson.storage !== undefined)
							storageList = returnedJson.storage;
							
						// remove any sites that do not have storage areas.
						for ( var i = storageList.length - 1; i >= 0; i-- )
							if (storageList[ i ].storage_areas.length === 0)
								storageList.splice( i, 1 );
						
						// update the state with the list of returned storage areas.
						setStorageAreas( storageList );
						
					}
					
					// if the return code is 401 then either the data-management token or the gateway-backend
					// token has expired. we should renew them.
					if (apiResult.status === 401)
					{
						const currentTask: CurrentTask = { taskType: TaskType.NONE };
						props.renewTokens( { currentTask: currentTask } );
					}
					
				}
				catch (e)
				{
					console.log( e );
				}
			
			}
			
      		}
      		catch (e)
      		{
			console.log(e);
		}
  	
  	} // loadStorageAreas

	//	-------------------------------------------------
	//
	//	function that handles a change in the selected namespace on the search form
	//
	//	-------------------------------------------------
  	
  	const namespaceOnChangeHandler = function( args: { namespace: string } )
  	{
  	
  		setNamespaceValue( args.namespace );
  	
  	} // namespaceOnChangeHandler

	//	------------------------------------------------------------
	//
	//	Restrict input box entry to numbers only, plus . , + -
	//
	//	------------------------------------------------------------
  	
  	const numericKeysOnly = (event: React.KeyboardEvent<HTMLInputElement>) =>
  	{
  	
  		if ((event.charCode < 48 || event.charCode > 57) && (event.charCode < 43 || event.charCode > 46))
  			event.preventDefault();
  	
  	} // numericKeysOnly

	//	------------------------------------------------------------
	//
	//	Handler for lost-focus events on the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const onBlurEvent = (event: React.FocusEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  	
  		if (inputBox.name === 'lifetime')
  		{
			
			// params.
			let lifetimeNumeric: number = 0.0;
			try
			{
				lifetimeNumeric = Number( sParamsLifetime );
			}
			catch (e)
			{
			}
  			setTableParamsLifetime( lifetimeNumeric );
  			 
  		}
  	
  	} // onBlurEvent

	//	------------------------------------------------------------
	//
	//	Handler for changes to the radio buttons.
	//
	//	------------------------------------------------------------
  	
  	const onClickHandler = (event: React.MouseEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.currentTarget;
			
		// if we click a search button on the search form then open a new results tab.
		if (inputBox.name === "searchData")
		{
		
			initiateDMSearch( 	{
						namespace: sNamespaceValue,
						filename: sFilterFilename
						} );
		
			// filter values.
			//setTableNamespace( sNamespaceValue );
			//setTableFilename( sFilterFilename );
  			
			// run the query.
			//setTableDisplayed( true );
			//setTableRenderCount( sTableRenderCount + 1 );
		
		}

		// refresh jobs is pressed, so get a list of jobs from the API.		
		if (inputBox.name === "refreshJobs")
	  		props.getDataManagementJobs(	{
	  						gatewayBackendToken: props.gatewayBackendToken,
	  						dataManagementToken: props.dataManagementToken
	  						} );
  	
  	} // onClickHandler

	//	------------------------------------------------------------
	//
	//	Handler for button clicks.
	//
	//	------------------------------------------------------------
  	
  	const onClickButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
  	{

		const button: HTMLButtonElement = event.currentTarget;
			
		// minimise/maximise the filter box if the user clicks minimise/maximise.
		if (button.name === "minimiseFilter")
			setFilterMaximised( false );
		if (button.name === "maximiseFilter")
			setFilterMaximised( true );
			
		// minimise/maximise the parameters box if the user clicks minimise/maximise.
		if (button.name === "minimiseParameters")
			setParametersMaximised( false );
		if (button.name === "maximiseParameters")
			setParametersMaximised( true );
			
		// minimise/maximise the details box if the user clicks minimise/maximise.
		if (button.name === "minimiseDetails")
			setDetailsMaximised( false );
		if (button.name === "maximiseDetails")
			setDetailsMaximised( true );
		
	} // onClickButtonHandler

	//	------------------------------------------------------------
	//
	//	Handler for DIV clicks.
	//
	//	------------------------------------------------------------
  	
  	const onClickDivHandler = (event: React.MouseEvent<HTMLDivElement>) =>
  	{

		const divElement: HTMLDivElement = event.currentTarget;
				
		// check which data item was clicked on.
		if (divElement.id.length > 7)
			if (divElement.id.slice( 0, 7 ) === "expand_")
			{
		
				// get the ID and convert to numeric.
				const divIDstr: string = divElement.id.slice( 7 - divElement.id.length );
				var jobIndex: number = -1;
				try
				{
					jobIndex = Number( divIDstr );
				}
				catch
				{
				}
				
				// get a copy of the expanded jobs list.
				var jobIDs: string[] = sJobIDExpanded;
				
				// check if this job ID already exists in the expanded jobs list.
				const index: number = jobIDs.indexOf( props.dataManagementJobs[ jobIndex ].job_id );
				if (index > -1)
				
					// remove the job ID from the list of expanded jobs.
					jobIDs.splice( index, 1 );
				
				else
					
					// add the job ID to the list of expanded jobs.
					jobIDs.push( props.dataManagementJobs[ jobIndex ].job_id );
				
				// update the state.
				setJobIDExpanded( jobIDs );
				setJobRenderCount( sJobRenderCount + 1 );
					
			}
		
	} // onClickDivHandler

	//	--------------------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	--------------------------------------------------------------------------

	//	-------------------------------------------------
	//
	//	Component that displays a list of data-management jobs.
	//
	//	-------------------------------------------------
	
	function DataManagementJobs( args:	{
						dataManagementJobs: JobType[],
						storageAreas:	{
								site: string,
								storage_areas:	{
										associated_storage_id: string,
										storage_id: string,
										storage_type: string,
										relative_path: string,
										identifier: string
										}[]
								}[],
						jobIDExpanded: string[]
						} )
	{
	
		// build list of 'to' storage areas.
		var toStorageArea: string[] = [];
		for ( var i = 0; i < args.dataManagementJobs.length; i++ )
			toStorageArea.push( getStorageAreaFromID( { storageAreaUUID: args.dataManagementJobs[ i ].to_storage_area_uuid } ) );

		//	-------------------------------------------------
		//
		//	search through the storages state array to find this uuid.
		//
		//	-------------------------------------------------
	  	
	  	function getStorageAreaFromID( fnArgs:	{
	  							storageAreaUUID: string
	  							} )
	  	{
	  	
	  		var storageArea: string = fnArgs.storageAreaUUID;
	  		
	  		for ( var site = 0; site < args.storageAreas.length; site++ )
	  			for ( var area = 0; area < args.storageAreas[ site ].storage_areas.length; area++ )
	  				if (args.storageAreas[ site ].storage_areas[ area ].storage_id === fnArgs.storageAreaUUID)
	  					storageArea = args.storageAreas[ site ].site + " -> " + args.storageAreas[ site ].storage_areas[ area ].identifier;
	  	
	  		// return something.
	  		return storageArea;
	  	
	  	} // getStorageAreaFromID

		//	-------------------------------------------------
		//
		//	Display a single item (container, dataset or file).
		//
		//	-------------------------------------------------
	
		function Item( itemArgs:	{
						item: JobItemType,
						type: string,
						expanded: boolean
						} )
		{
		
			return	(
		
				<tr style = {{ flex: '0 0 auto', width: '100%', display: itemArgs.expanded === true ? 'flex' : 'none', flexDirection: 'row' }} >
					<td style = {{ flex: '0 0 30px', borderStyle: 'none' }} />
					<td style = {{ flex: '0 0 0px', margin: '0px 5px 0px 5px', borderStyle: 'none' }} >
						{
						itemArgs.item.name
						}
					</td>
					<td style = {{ flex: '0 0 0px', margin: '0px 5px 0px 5px', borderStyle: 'none' }} >
						{
						itemArgs.type
						}
					</td>
					<td style = {{ flex: '0 0 0px', margin: '0px 5px 0px 5px', borderStyle: 'none' }} >
						{
						DisplayFileSize( { bytes: itemArgs.item.bytes } )
						}
					</td>
					<td style = {{ flex: '0 0 10px', borderStyle: 'none' }} />
				</tr>
			
				)
		
		} // Item
	
		return	(
			
			<div className = "data-job-status" >
			
		    	    	<div className = "flex-15px"></div>
		    	    	<div style = {{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', width: '100%' }}>
		    			<div className = "form-title" style = {{ flex: '1 1 0px' }}>{t( "Current jobs" )}</div>
	    			</div>
		    	    	<div className = "flex-15px"></div>
		    	    	<div className = "data-management-scrollbox-container" >
			
					<div className = "data-management-scrollbox">
				
						{
						args.dataManagementJobs.map
						(
							( item, index ) =>
							(
								<div style = {{ flex: '0 0 auto', width: '100%', display: 'flex', flexDirection: 'column' }} >
									<div style = {{ flex: '0 0 auto', width: '100%', display: 'flex', flexDirection: 'row' }} >
										<div style = {{ flex: '0 0 15px' }} />
										<div style = {{ flex: '1 1 0px' }} >
											{t( 'Status' )}: {item.status}
										</div>
									</div>
									{
									item.status !== 'NOT FOUND' ?
									<div style = {{ flex: '0 0 auto', width: '100%', display: 'flex', flexDirection: 'row' }} >
										<div style = {{ flex: '0 0 15px' }} />
										<div	style = {{ flex: '0 0 15px', cursor: 'pointer' }}
											id = {'expand_' + index.toString()}
											onClick = {onClickDivHandler} >{args.jobIDExpanded.indexOf( item.job_id ) > -1 ? '-' : '+'}</div>
										<div style = {{ flex: '1 1 0px' }} >
											Move {item.containers.length + item.datasets.length + item.files.length} item(s) from namespace '{item.parent_namespace}' to location '{toStorageArea[ index ]}'
										</div>
									</div> :
									<></>
									}
									{
									item.status !== 'NOT FOUND' ?
									<table style = {{ width: '100%' }}>
										{
										item.containers.map
										(
											( container ) =>
											(
											<Item	item = {container}
												type = 'CONTAINER'
												expanded = {args.jobIDExpanded.indexOf( item.job_id ) > -1} />
											)
										)
										}
									</table> :
									<></>
									}
									{
									item.status !== 'NOT FOUND' ?
									<table style = {{ width: '100%' }}>
										{
										item.datasets.map
										(
											( dataset ) =>
											(
											<Item	item = {dataset}
												type = 'DATASET'
												expanded = {args.jobIDExpanded.indexOf( item.job_id ) > -1} />
											)
										)
										}
									</table> :
									<></>
									}
									{
									item.status !== 'NOT FOUND' ?
									<table style = {{ width: '100%' }}>
										{
										item.files.map
										(
											( file ) =>
											(
											<Item	item = {file}
												type = 'FILE'
												expanded = {args.jobIDExpanded.indexOf( item.job_id ) > -1} />
											)
										)
										}
									</table> :
									<></>
									}
									{
									item.status !== 'NOT FOUND' ?
									<div style = {{ flex: '0 0 auto', width: '100%', display: args.jobIDExpanded.indexOf( item.job_id ) > -1 ? 'flex' : 'none', flexDirection: 'row' }} >
										<div style = {{ flex: '0 0 30px' }} />
										<div style = {{ flex: '1 1 0px' }} >
											Expires at: {item.expires_at}
										</div>
									</div> :
									<></>
									}
									<div style = {{ flex: '0 0 5px' }} />
								</div>
							)
						)
						}
						
					</div>
					
				</div>
		    	    	<div className = "flex-15px"></div>
				<div className = "form-button-container">
		    	    		<ToolButton	key = {t("Refresh")}
		    	    				name = "refreshJobs"
		    	    				onClick = {onClickHandler}
		    	    				text = {t("Refresh")}
		    	    				primary = {false} />
		    	    				
		    	    		<div style = {{ width: '20px' }} />
					<div className = {props.jobsLoading === true ? "search-results-table-loading" : "search-results-table-loaded"} style = {{ padding: '0px 0px 0px 0px' }}>
						<img	className = "animated-gears"
							src = {gearsIcon}
							alt = ""
							width = "40"
							height = "40" />
						Loading
					</div>
		    	    	</div>
				
			</div>
		
			)
	
	} // DataManagementJobs

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------

	return	(
	
		<div className = "data-management-container">
		
	    		<div	style = {{ flex: '0 0', display: 'flex', flexDirection: 'column', height: '100%' }}>
		
				{/*
				//
				//	minimised filter box. click once to expand.
				//
				*/}
			    	<button	className = "minimised-filter"
			    			name = "maximiseFilter"
			    			type = "button"
			    			onClick = {onClickButtonHandler}
			    			data-maximised = {sFilterMaximised === true ? "T" : "F"} >
			    	    	<div className = "flex-15px"></div>
			    		<div className = "maximise">&raquo;</div>
			    		<div className = "flex-row">
				    		<div className = "rotated-text-box">Filter</div>
				    	</div>
			    	</button>
			
				{/*
				//
				//	maximised filter box.
				//
				*/}
				<div	className = "search-form"
					data-maximised = {sFilterMaximised === true ? "T" : "F"}>
				
			    	    	<div className = "flex-15px"></div>
			    	    	<div className = "flex-row">
			    			<div className = "form-title">{t( "Filter" )}</div>
			    			<div className = "flex-expanding"></div>
			    			<button	className = "minimise"
			    					name = "minimiseFilter"
			    					type = "button"
			    					onClick = {onClickButtonHandler}>&laquo;</button>
			    			<div style = {{ flex: '0 0 15px' }}></div>
		    			</div>
			    	    	<div className = "flex-15px"></div>
			    	    	
			    	    	{/*
			    	    	//
			    	    	//	namespace filter box
			    	    	//
			    	    	*/}
		    			<div className = "filter-combobox-container">
						<DataManagementNamespaceList	key = {props.dataManagementToken.access_token + props.gatewayBackendToken.access_token}
										changeNamespace = {namespaceOnChangeHandler}
										placeholderShown = {sNamespaceValue === ''}
										value = {sNamespaceValue}
										dataManagementToken = {props.dataManagementToken} />
					</div>
			    	    	<div className = "flex-10px"></div>
			    	    	
			    	    	{/*
			    	    	//
			    	    	//	filename filter box
			    	    	//
			    	    	*/}
			    	    	<div className = "filter-textbox-container" >
	    	    				<input	className = "filter-textbox"
	    	    					type = "text"
	    	    					name = "filename"
	    	    					placeholder = {t("Filename")}
	    	    					onChange = {inputHandler}
	    	    					value = {sFilterFilename}
							maxLength = {255} />
			    	    	</div>
			    	    	<div className = "flex-10px"></div>
					
		    			{/*
		    			//
		    			//	search button
		    			//
		    			*/}
					<div className = "form-button-container">
			    	    		<ToolButton	key = {t("Search") + (props.tabVisible ? 't' : 'f')}
			    	    				name = "searchData"
			    	    				onClick = {onClickHandler}
			    	    				text = {t("Search")}
			    	    				primary = {true} />
			    	    	</div>
			    		
				</div>
				
				<div style = {{ flex: '0 0 15px' }} />
			
				{/*
				//
				//	minimised parameters box. click once to expand.
				//
				*/}
			    	<button	className = "minimised-filter"
			    			name = "maximiseParameters"
			    			type = "button"
			    			onClick = {onClickButtonHandler}
			    			data-maximised = {sParametersMaximised === true ? "T" : "F"} >
			    	    	<div className = "flex-15px"></div>
			    		<div className = "maximise">&raquo;</div>
			    		<div className = "flex-row">
				    		<div className = "rotated-text-box">{t("Parameters")}</div>
				    	</div>
			    	</button>
			
				{/*
				//
				//	maximised parameters box.
				//
				*/}
				<div	className = "search-form"
					data-maximised = {sParametersMaximised === true ? "T" : "F"}>
				
			    	    	<div className = "flex-15px"></div>
			    	    	<div className = "flex-row">
			    			<div className = "form-title">{t( "Parameters" )}</div>
			    			<div className = "flex-expanding"></div>
			    			<button	className = "minimise"
			    					name = "minimiseParameters"
			    					type = "button"
			    					onClick = {onClickButtonHandler}>&laquo;</button>
			    			<div style = {{ flex: '0 0 18px' }}></div>
		    			</div>
			    	    	<div className = "flex-15px"></div>
			    	    	
			    	    	{/*
			    	    	//
			    	    	//	staging seconds parameter box
			    	    	//
			    	    	*/}
			    	    	<div className = "filter-textbox-container" >
	    	    				<input	className = "filter-textbox"
	    	    					type = "text"
	    	    					name = "lifetime"
							onKeyPress = {numericKeysOnly}
	    	    					placeholder = {t("Lifetime [s]")}
	    	    					onChange = {inputHandler}
							onBlur = {onBlurEvent}
							maxLength = {50}
							value = {sParamsLifetime} />
			    	    	</div>
			    	    	<div className = "flex-10px"></div>
			    		
				</div>
				
				<div style = {{ flex: '0 0 15px' }} />
			
				{/*
				//
				//	minimised details box. click once to expand.
				//
				*/}
			    	<button	className = "minimised-filter"
			    			name = "maximiseDetails"
			    			type = "button"
			    			onClick = {onClickButtonHandler}
			    			data-maximised = {sDetailsMaximised === true || sSelectedItems.length === 0 ? "T" : "F"} >
			    	    	<div className = "flex-15px"></div>
			    		<div className = "maximise">&raquo;</div>
			    		<div className = "flex-row">
				    		<div className = "rotated-text-box">{t("Details")}</div>
				    	</div>
			    	</button>
			
				{/*
				//
				//	maximised details box.
				//
				*/}
				<div	className = "search-form"
					data-maximised = {sDetailsMaximised === true && sSelectedItems.length > 0 ? "T" : "F"}
					style = {{ flex: '1 1' }} >
				
			    	    	<div className = "flex-15px"></div>
			    	    	<div style = {{ display: 'flex', flexDirection: 'row', flex: '0 0' }} >
			    			<div className = "form-title">{t( "Details" )}</div>
			    			<div className = "flex-expanding"></div>
			    			<button	className = "minimise"
			    					name = "minimiseDetails"
			    					type = "button"
			    					onClick = {onClickButtonHandler}>&laquo;</button>
			    			<div style = {{ flex: '0 0 18px' }}></div>
		    			</div>
			    	    	<div className = "flex-10px"></div>
			    	    	
			    	    	<div className = "data-management-scrollbox-container" style = {{ width: '279px', margin: '2px 15px 10px 15px'}}>
			
						<DetailsPane	dataManagementToken = {props.dataManagementToken}
								selectedItems = {sSelectedItems}
								renewTokens = {props.renewTokens}
								storageAreas = {sStorageAreas} />
						
					</div>
			    	    	
			    	    	<div className = "flex-10px"></div>
			    		
				</div>
		    	
		    	</div>
			<div className = "transparent-vertical-separator"></div>
			
			{/*
			//
			//	run a search query with the given parameters, and display the results in a table.
			//
			*/}
			<DataManagementTable	key = {sTableNamespace + sTableFilename + sTableRenderCount.toString() + props.dataManagementToken.access_token + props.gatewayBackendToken.access_token}
						tableDisplayed = {sTableDisplayed}
						namespace = {sTableNamespace}
						filename = {sTableFilename}
						paramsLifetime = {sTableParamsLifetime}
		    				authToken = {props.authToken}
		    				dataManagementToken = {props.dataManagementToken}
		    				siteCapabilitiesToken = {props.siteCapabilitiesToken}
		    				gatewayBackendToken = {props.gatewayBackendToken}
		    				renewTokens = {props.renewTokens}
		    				taskExecutor = {props.taskExecutor}
		    				getDataManagementJobs = {props.getDataManagementJobs}
		    				storageAreas = {sStorageAreas}
		    				setStorageAreas = {setStorageAreas}
		    				setSelectedItems = {setSelectedItems} />
			<div className = "transparent-vertical-separator"></div>
			
			{/*
			//
			//	display a list of current data-management jobs.
			//
			*/}
			<DataManagementJobs	key = {sJobRenderCount.toString() + (props.jobsLoading === true ? 'Y' : 'N')}
					 	dataManagementJobs = {props.dataManagementJobs}
					 	storageAreas = {sStorageAreas}
					 	jobIDExpanded = {sJobIDExpanded} />
		
		</div>
		
		)

} // DataManagement
