import React, { useState } from 'react';
import '../App.css';
import './visualisation.css';
import CSS from 'csstype';

// icons

// classes

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function VisualisationSkyserver( props:	{
								running: boolean,
								launchParams:	{} | null,
								params:	{
										updateState: any
										} | null
								} )
{
	
	//	------------------------------------------------------------
	//
	//	main HTML code.
	//
	//	------------------------------------------------------------
	
	if (props.running === true)
		return	(
			<iframe src = 'https://skyserver.sdss.org/dr1/en/tools/chart/chart.asp?opt=GO' className = "iframe" />
			)
	else
		return	(
			<></>
			)

} // VisualisationSkyserver
