import React, { Suspense, useState, useEffect } from 'react';
import './App.css';
import CSS from 'csstype';
import useLocalStorage from 'use-local-storage';
import './i18n'
import { useTranslation } from 'react-i18next';

// images
//import skaLogo from './icons/logo-SKA-small.png';
import skaoLogo from './icons/skao-logo.1270.png';
import srcLogo from './icons/srcnet-dark.svg';
import srcLightLogo from './icons/srcnet-light.svg';
//import tangerineLogo from './icons/tangerine-combined-scaled.png';
import folderIcon from './icons/folder.256.png';
import workflowIcon from './icons/workflow.512.png';
import jupyterIcon from './icons/jupyter.380.png';
import terminalIcon from './icons/terminal.256.png';
import homeIcon from './icons/home.614.png';
import magnifierIcon from './icons/magnifier.512.png';
import chartIcon from './icons/chart.512.png';
import monitorIcon from './icons/monitor.1600.png';
import planetIcon from './icons/planet.512.png';
import dishIcon from './icons/dish.512.png';
import galaxyIcon from './icons/galaxy.512.png';
//import homeWhiteIcon from './icons/home-white.512.png';
//import folderWhiteIcon from './icons/folder-white.512.png';
//import personWhiteIcon from './icons/person-white.512.png';
//import magnifierWhiteIcon from './icons/magnifier-white.512.png';
import lightdarkwhiteIcon from './icons/lightdark-white.png';
import lightdarkblackIcon from './icons/lightdark-black.png';

// flag
import usukFlag from "./icons/US-UK-flag.png";
import dutchFlag from "./icons/Netherlands-flag.png";

// types
import { AccessToken } from './types';
import { JobType } from './types';

// types relating to tasks.
import { TaskType } from './tasks';
import { UserSettings } from './tasks';
import { UserServiceToken } from './tasks';
import { MoveDataToStorage } from './tasks';
import { CurrentTask } from './tasks';

// classes
import { SearchResultsType } from './search-catalog/search-catalog';
import Home from './home/home';
import SearchCatalog from './search-catalog/search-catalog';
import Tool from './tools/tool';
import UserControl from './user-control/user-control';
import ProjectButton from './project-button';
import UserControlOIDCToken from './user-control/user-control-oidc-token';
import { HideDropdownMenu } from './functions';
import SearchCompute from './search-compute/search-compute';
import DataManagement from './data-management/data-management';
import Visualisation from './visualisation/visualisation';
import Preferences from './user-preferences/preferences';

// LEDA 2605481

//	--------------------------------------------------------------------------
//
//	C O N S T A N T S
//
//	--------------------------------------------------------------------------

const TITLE = 'SRC-NET Prototype';
	
// language.
const LANGUAGE_OPTIONS = [	{ value: "en", label: "English", icon: usukFlag },
				{ value: "nl", label: "Nederlands", icon: dutchFlag } ];

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

enum ToolType
{
	HOME,
	SEARCH_CATALOG,
	SEARCH_COMPUTE,
	DATA_MANAGEMENT,
	NOTEBOOK,
	VISUALISE_DATA,
	WORKFLOW,
	MY_FILES,
	TERMINAL,
	VIRTUAL_MACHINE,
	OIDC_TOKENS
}

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

// ------------------------------------------------------------
//
//	This dropdown mimics a droplist listbox, but
//	allows an image to be displayed next to each list item.
//
// ------------------------------------------------------------

function LanguageDropdownMenu( args:	{
					sLanguageDropdownMenuDisplayed: boolean,
					setLanguageDropdownMenuDisplayed: any,
					buttonHandler: any
					} )
{

	// function that monitors for mouse clicks. we need to add {ref} to the DIV element of the dropdown menu.
	const { ref } = HideDropdownMenu(	{
						sDropdownDisplayed: args.sLanguageDropdownMenuDisplayed,
						setDropdownDisplayed: args.setLanguageDropdownMenuDisplayed
						} );

	//	------------------------------------------------------------
	//
	//	A HTML component that renders a single menu item on the
	//	dropdown menu.
	//
	//	------------------------------------------------------------

	function MenuItem( args: { name: string, text: string, icon?: string, onClick: any } )
	{

		return	(
		
			<button name = {'btnLanguage_' + args.name} className = "dropdown-list-item" onClick = {args.onClick}>
				<div className = "dropdown-list-item-text">{args.text}</div>
				<div className = "dropdown-list-item-image">
					<img src = {args.icon} alt = "" width = "30" height = "16" />
				</div>
			</button>
		
			)

	} // MenuItem

	return	(

		<div	ref = {ref}
			style =	{{
					display: (args.sLanguageDropdownMenuDisplayed === true ? 'block' : 'none'),
					position: 'absolute',
					top: '100%',
					right: '0%',
					border: 'none',
					zIndex: '9'
					}} >
				
			<div className = "language-dropdown-menu">
				{
				LANGUAGE_OPTIONS.map
				(
					(item, index) =>
					(
						MenuItem( {name: item.value, text: item.label, icon: item.icon, onClick: args.buttonHandler} )
					)
				)
				}
			</div>
			
		</div>
			
		)

} // LanguageDropdownMenu

// ------------------------------------------------------------
//
//	Small icons that appear at the top of the screen to
//	allow the user to quickly navigate the portal.
//
// ------------------------------------------------------------

function NavigationIcon( args:	{
					icon: string,
					tooltip: string,
					name: string,
					onClick: any
					} )
{

	return	(
		<button	className = "navigation-icon"
				name = {args.name}
				onClick = {args.onClick}
				title = {args.tooltip}>
				
			<img	src = {args.icon}
				alt = ""
				width ="24"
				height = "24" />
				
		</button>
		)
	
} // NavigationIcon

//	--------------------------------------------------------------------------
//
//	M A I N   A P P L I C A T I O N
//
//	--------------------------------------------------------------------------

function App()
{

	// for some reason I need to put these style properties here, because when I put them in the CSS it doesn't work properly!
  	const projectSpace: CSS.Properties =
  			{
  			width: '100%',
  			height: '100%',
  			display: 'flex',
  			flexDirection: 'row',
  			flex: '1 1 0px',
  			marginBottom: '10px'
  			}

	// multi-language support
	const { i18n, t } = useTranslation();
	
	// get default light/dark mode setting.
	const DEFAULT_DARK = window.matchMedia( '(prefers-color-scheme: dark)' ).matches;
	
	// define an empty token.
	const EMPTY_TOKEN: AccessToken = { access_token: "", token_type: "", refresh_token: "", expires_in: 0, scope: "", id_token: "", expires_at: 0 };

	// set a page title.
	useEffect( () => { document.title = TITLE; }, [] );
	
	//	-------------------------------------------------
  	//		
  	//	Get initial values for state variables.
  	//
	//	-------------------------------------------------
  	
  	// check the cookies to see if we are currently logged in.
  	var loggedIn: boolean = (localStorage.getItem( 'science_gateway_logged_in' ) !== null);
				
	// if we are logged in then try to get the site-capability token from the cookies.
	var authToken: AccessToken = EMPTY_TOKEN;
	var siteCapabilitiesToken: AccessToken = EMPTY_TOKEN;
	var gatewayBackendToken: AccessToken = EMPTY_TOKEN;
	var dataManagementToken: AccessToken = EMPTY_TOKEN;
	if (loggedIn === true)
	{
		var cookie: string | null = localStorage.getItem( 'science_gateway_auth_token' );
		if (cookie !== null)
			authToken = JSON.parse( cookie );
		var cookie: string | null = localStorage.getItem( 'science_gateway_site_capabilities_token' );
		if (cookie !== null)
			siteCapabilitiesToken = JSON.parse( cookie );
		var cookie: string | null = localStorage.getItem( 'science_gateway_data_management_token' );
		if (cookie !== null)
			dataManagementToken = JSON.parse( cookie );
		var cookie: string | null = localStorage.getItem( 'science_gateway_gateway_backend_token' );
		if (cookie !== null)
			gatewayBackendToken = JSON.parse( cookie );
	}
	
	//	-------------------------------------------------
  	//		
  	//	State variables
  	//
	//	-------------------------------------------------

  	// general:
  	const [sSelectedProject, setSelectedProject] = useState<string>( '' );
  	const [sSelectedTool, setSelectedTool] = useState<ToolType>( ToolType.HOME );
	
	// re-direct uri.
	const [sRedirectURI, setRedirectURI] = useState<string>( '' );
	
	// language:
	const [sLanguageDropdownMenuDisplayed, setLanguageDropdownMenuDisplayed] = useState<boolean>( false );
	const [sLanguageIndex, setLanguageIndex] = useState<number>( 0 );
  	
  	// user control:
  	//const [loginDisplayed, setLoginDisplayed] = useState<boolean>( false );
  	//const [sLoggedIn, setLoggedIn] = useState<boolean>( false );
  	const [sUserDropdownMenuDisplayed, setUserDropdownMenuDisplayed] = useState<boolean>( false );
  	const [sUsername, setUsername] = useState<string>( i18n.t( 'Unknown User' ) );
  	
  	// light/dark mode theme.
	const [sTheme, setTheme] = useLocalStorage( 'theme', DEFAULT_DARK ? 'dark' : 'light' );
  	
  	// notebook tool:
  	const [sJupyterURL, setJupyterURL] = useState<string>( '' );
  	const [sJupyterRender, setJupyterRender] = useState<number>( 0 );
  	
  	// visualisation tool:
  	const [sSearchPosition, setSearchPosition] = useState< { id: string, ra: number, dec: number }[]>( [] );
  	
  	// access token for OIDC.
  	const [sLoggedIn, setLoggedIn] = useState<boolean>( loggedIn );
  	const [sAuthToken, setAuthToken] = useState<AccessToken>( authToken );
  	
  	// access token for data-management API.
  	const [sDataManagementToken, setDataManagementToken] = useState<AccessToken>( dataManagementToken );
  	
  	// access token for site-capabilities API.
  	const [sSiteCapabilitiesToken, setSiteCapabilitiesToken] = useState<AccessToken>( siteCapabilitiesToken );
  	
  	// access token for gateway-backend API.
  	const [sGatewayBackendToken, setGatewayBackendToken] = useState<AccessToken>( gatewayBackendToken );

	// Dialog boxes
	const [sShowPreferencesDialog, setPreferencesDialogDisplay] = useState<boolean>( false );
  	
  	// data-management jobs.
	const [sDataManagementJobs, setDataManagementJobs] = useState< JobType[] >( [] );
	const [sJobsLoading, setJobsLoading] = useState< boolean >( false );
	
	//	-------------------------------------------------
	//
	//	Build a list of parameters for the visualisation
	//	tools.
	//
	//	The parameter list is an array that consists of:
	//
	//	1. a code, i.e. 'aladin'
	//	2. a set of launch parameters. if these parameters are not null then the
	//		tool will automatically be launched without user intervention.
	//	3. a set of other parameters required by the tool.
	//
	//	-------------------------------------------------
	
	function buildVisualisationParams()
	{
	
		//	-------------------------------------------------
		//
		//	parameters for Aladin.
		//
		//	-------------------------------------------------
		
		var aladinLaunchParams:	{
						position:	{
								ra: number,
								dec: number,
								obs_publisher_did: string 
								} | null
						} | null = null;
		const aladinParams:	{
					viewDataFromAladinFootprint: any,
					updateState: any
					} | null =	{
							viewDataFromAladinFootprint: viewDataFromAladinFootprint,
							updateState: updateState
							};
		
		//	-------------------------------------------------
		//
		//	parameters for SkyServer.
		//
		//	-------------------------------------------------

		const skyserverParams:	{
					updateState: any
					} | null =	{
							updateState: updateState
							};
		
		//	-------------------------------------------------
		//
		//	combine the parameters into a single data structure.
		//
		//	-------------------------------------------------
	
		const params:	{
				code: string,
				launchParams: {} | null,
				params: {} | null
				}[] =	[
						{
							code:		'aladin',
							launchParams:	aladinLaunchParams,
							params:	aladinParams
						},
						{
							code:		'skyserver',
							launchParams:	null,
							params:	skyserverParams
						}
					];
		
		// return something.
		return params;
	
	} // buildVisualisationParams

	//	-------------------------------------------------
	//
	//	Call the /token endpoint to get the access token.
	//
	//	-------------------------------------------------
  	
  	async function getAccessToken( args:	{
  						code: string,
  						redirectURI: string
  						} )
  	{
		
		// interface for parsing the returned JSON from /login.
		interface uriResult
		{
			//authorization_uri: string;
			login_url: string;
		}
  	
		try
		{
			
  			// call the /token endpoint here.
			//const apiResult = await fetch( 'http://localhost:8080/v1/token?code=' + args.code + '&redirect_uri=' + args.redirectURI,
			const apiResult = await fetch( 'https://gateway.srcdev.skao.int/tannet-api/v1/token?code=' + args.code + '&redirect_uri=' + args.redirectURI,
								{headers: {'Content-Type': 'application/json'}} );

			if (apiResult.status === 200)
			{
			
				const returnedJson = await apiResult.json();
				console.log( returnedJson );
				
				// initialise new tokens to empty.
				var newAuthToken: AccessToken = EMPTY_TOKEN;
				var newSiteToken: AccessToken = EMPTY_TOKEN;
				var newDMToken: AccessToken = EMPTY_TOKEN;
				var newGatewayToken: AccessToken = EMPTY_TOKEN;
				
				// was the auth token returned OK ?
				if (returnedJson.auth_token_status_code === 200)
				{
				
					// get the authentication token.
					const token = returnedJson.auth_token;
					
					// store the access token so we can use it for future exchanges.
					newAuthToken = { access_token: token.access_token, token_type: token.token_type, refresh_token: token.refresh_token,
									expires_in: token.expires_in, scope: token.scope, id_token: token.id_token, expires_at: token.expires_at }
					setAuthToken( newAuthToken );
					
					// was the site-capabilities token returned OK ?
					if (returnedJson.site_capabilities_token_status_code === 200)
					{
					
						// get the site-capabilities token.
						const siteToken = returnedJson.site_capabilities_token;
						
						// store the access token so we can use it for future exchanges.
						newSiteToken = { access_token: siteToken.access_token, token_type: siteToken.token_type, refresh_token: siteToken.refresh_token,
										expires_in: siteToken.expires_in, scope: siteToken.scope, id_token: siteToken.id_token,
										expires_at: siteToken.expires_at }
						setSiteCapabilitiesToken( newSiteToken );
						
						// set cookies so that these state variables survive a refresh.
						localStorage.setItem( 'science_gateway_site_capabilities_token', JSON.stringify( newSiteToken ) );
					
					}
					
					// was the data-management token returned OK ?
					if (returnedJson.data_management_token_status_code === 200)
					{
					
						// get the site-capabilities token.
						const dmToken = returnedJson.data_management_token;
						
						// store the access token so we can use it for future exchanges.
						newDMToken = { access_token: dmToken.access_token, token_type: dmToken.token_type, refresh_token: dmToken.refresh_token,
										expires_in: dmToken.expires_in, scope: dmToken.scope, id_token: dmToken.id_token,
										expires_at: dmToken.expires_at }
						setDataManagementToken( newDMToken );
						
						// set cookies so that these state variables survive a refresh.
						localStorage.setItem( 'science_gateway_data_management_token', JSON.stringify( newDMToken ) );
					
					}
					
					// was the gateway-backend token returned OK ?
					if (returnedJson.gateway_backend_token_status_code === 200)
					{
					
						// get the gateway-backend token.
						const gatewayToken = returnedJson.gateway_backend_token;
						
						// store the access token so we can use it for future exchanges.
						newGatewayToken = { access_token: gatewayToken.access_token, token_type: gatewayToken.token_type, refresh_token: gatewayToken.refresh_token,
										expires_in: gatewayToken.expires_in, scope: gatewayToken.scope, id_token: gatewayToken.id_token,
										expires_at: gatewayToken.expires_at }
						setGatewayBackendToken( newGatewayToken );
						
						// set cookies so that these state variables survive a refresh.
						localStorage.setItem( 'science_gateway_gateway_backend_token', JSON.stringify( newGatewayToken ) );
					
					}
					
					// set cookies so that these state variables survive a refresh.
					localStorage.setItem( 'science_gateway_logged_in', 'y' );
					localStorage.setItem( 'science_gateway_auth_token', JSON.stringify( newAuthToken ) );
					setLoggedIn( true );
						
					// get the username.
					getUsername( { accessToken: newAuthToken } );
					
					// get the user settings.
					loadUserSettings( { gatewayBackendToken: newGatewayToken } );
				
				}
						
			}
		
      		}
      		catch (e)
      		{
      			console.log(e);
		}
  		
  	} // getAccessToken

	//	------------------------------------------------------------
	//
	//	An asynchronous function that loads a list of data-management
	//	API jobs.
	//
	//	------------------------------------------------------------
  	
  	async function getDataManagementJobs( args:	{
  							gatewayBackendToken?: AccessToken,
  							dataManagementToken?: AccessToken
  							} )
  	{
  	
		setJobsLoading( true );
		
		// choose which tokens to use, either the ones from state or the supplied ones.
		var gatewayBackendToken: AccessToken = sGatewayBackendToken;
		if (args.gatewayBackendToken !== undefined)
			gatewayBackendToken = args.gatewayBackendToken;
		var dataManagementToken: AccessToken = sDataManagementToken;
		if (args.dataManagementToken !== undefined)
			dataManagementToken = args.dataManagementToken;

		// only proceed if we have a gateway-backend access token and a data-management access token.
		if (gatewayBackendToken.access_token !== "" && dataManagementToken.access_token !== "")
		{
	
			try
			{

				//var urlCommand: string = 'http://localhost:8080/v1/data_management/list_jobs?';
				var urlCommand: string = 'https://gateway.srcdev.skao.int/tannet-api/v1/data_management/list_jobs?';
				
				// token.
				urlCommand = urlCommand +	'data_management_token=' + dataManagementToken.access_token;
							
				// build authorisation parameter.
				const authorisation: string = 'Bearer ' + gatewayBackendToken.access_token;

				try
				{
					
					const apiResult = await fetch( urlCommand,	{
											headers:	{
													'Content-Type': 'application/json',
													'Authorization': authorisation
													}
											} );
											
					if (apiResult.status === 200)
					{
					
						const returnedJson = await apiResult.json();

						// get jobs list.
						var jobsList: JobType[] = [];
						if (returnedJson.jobs_list !== undefined)
							jobsList = returnedJson.jobs_list;
							
						// remove jobs with status 'NOT FOUND'.
						for ( var i = jobsList.length - 1; i >= 0; i-- )
							if (jobsList[ i ].status === 'NOT FOUND')
								jobsList.splice( i, 1 );
						
						// update the state with the list of returned jobs.
						setDataManagementJobs( jobsList );
						
					}
					
					// if the return code is 401 then either the data-management token or the gateway-backend
					// token has expired. we should renew them.
					if (apiResult.status === 401)
					{
						const currentTask: CurrentTask = { taskType: TaskType.GET_DATA_MANAGEMENT_JOBS };
						renewTokens( { currentTask: currentTask } );
					}
					
				}
				catch (e)
				{
					setDataManagementJobs( [] );
					console.log( e );
				}
			
	      		}
	      		catch (e)
	      		{
	      			setDataManagementJobs( [] );
				console.log(e);
			}
			
		}
		else
			setDataManagementJobs( [] );
		
		// clear loading boolean.
  		setJobsLoading( false );
  	
  	} // getDataManagementJobs

	//	-------------------------------------------------
	//
	//	get the name of the currently logged in user
	//
	//	-------------------------------------------------
	
	async function getUsername(	args:	{
						accessToken: AccessToken
						} )
	{

		try
		{
			
  			// call the /token endpoint here.
			//const apiResult = await fetch( 'http://localhost:8080/v1/whoami?token=' + args.accessToken.access_token,
			const apiResult = await fetch( 'https://gateway.srcdev.skao.int/tannet-api/v1/whoami?token=' + args.accessToken.access_token,
								{headers: {'Content-Type': 'application/json'}} );
			if (apiResult.status === 200)
			{
			
				const returnedJson = await apiResult.json();
				
				// store the username.
				setUsername( returnedJson.name );
						
			}
		
      		}
      		catch (e)
      		{
		}
	
	} // getUsername

	//	-------------------------------------------------
	//
	//	the function to initiate a data-management search
	//	is initially undefined. it will be set when the
	//	data-management component has mounted.
	//
	//	-------------------------------------------------
	
	// the function runs in the child component (data-management), but is held here in state.
	const [sInitiateDataManagementSearch, setInitiateDataManagementSearch] = useState<any>( undefined );
	
	// this function is called by other child components when they want to initiate a data-management search.
	function initiateDataManagementSearchEvent( args:	{
								namespace: string,
								filename: string
								} )
	{
	
		// kick off the data-management search.
		sInitiateDataManagementSearch(	{
						namespace: args.namespace,
						filename: args.filename
						} );
							
		// switch to the data-management tab.
		setSelectedTool( ToolType.DATA_MANAGEMENT );
	
	} // initiateDataManagementSearchEvent
			
	// this function is called when the data-management component renders, and sets the function in state.
	const initiateDataManagementSearchUpdate = (newFunction: any) =>
	{
	
		setInitiateDataManagementSearch( () => newFunction );
		
	} // initiateDataManagementSearchUpdate

	//	-------------------------------------------------
	//
	//	launch a Jupyter notebook when the user selects one from
	//	the compute-resource search
	//
	//	-------------------------------------------------
  	
  	const launchNotebook = function( args: { url: string } )
  	{
  	
  		// update the url and the render count (we need this to force a re-render when the user clicks 'launch' again).
  		if (args.url !== undefined)
  		{
  			setJupyterURL( args.url );
  			var jupyterRender: number = sJupyterRender;
  			setJupyterRender( jupyterRender + 1 );
  			setSelectedTool( ToolType.NOTEBOOK );
  		}
  			
  	} // launchNotebook

	//	-------------------------------------------------
	//
	//	load and apply the user settings
	//
	//	-------------------------------------------------
  	
  	async function loadUserSettings( args: { gatewayBackendToken?: AccessToken } )
  	{
		
		// choose which token to use, either the one from state or the supplied one.
		var gatewayBackendToken: AccessToken = sGatewayBackendToken;
		if (args.gatewayBackendToken !== undefined)
			gatewayBackendToken = args.gatewayBackendToken;
		
		// only proceed if we have an access token for the backend.
		if (gatewayBackendToken.access_token !== '')
		{

			try
			{
  	
				const authorisation: string = 'Bearer ' + gatewayBackendToken.access_token;

	  			// call the /token endpoint here.
				//const apiResult = await fetch( 'http://localhost:8080/v1/get_preferences',
				const apiResult = await fetch( 'https://gateway.srcdev.skao.int/tannet-api/v1/get_preferences',
									{headers: {'Content-Type': 'application/json', 'Authorization': authorisation}} );
				if (apiResult.status === 200)
				{

					const returnedJson = await apiResult.json();
					
					// store the theme and language.
					setTheme( returnedJson.darkMode === true ? "dark" : "light" );
					
					if (returnedJson.language !== "")
					{
					
						// change the language.
						i18n.changeLanguage( returnedJson.language );
						
						// update the language index.
						var languageIndex: number = LANGUAGE_OPTIONS.findIndex( (element) => element.value === returnedJson.language );
						if (languageIndex === -1)
							languageIndex = 0;
						setLanguageIndex( languageIndex );
						
					}
						
				}
				
				// if we get a 401 then the token has expired. Renew tokens.
				if (apiResult.status === 401)
				{
					const currentTask: CurrentTask = { taskType: TaskType.LOAD_SETTINGS };
					renewTokens( { currentTask: currentTask } );
				}
			
	      		}
	      		catch (e)
	      		{
			}
		
		}
  	
  	} // loadUserSettings

	//	------------------------------------------------------------
	//
	//	Call the API to move data to a storage area.
	//
	//	------------------------------------------------------------
  	
  	async function moveDataToStorage( args:	{
  							settings: MoveDataToStorage,
  							gatewayBackendToken?: AccessToken,
  							dataManagementToken?: AccessToken
  							} )
  	{
  	
  		// choose which tokens to use, either the ones from state or the supplied ones.
  		var dataManagementToken: AccessToken = sDataManagementToken;
  		if (args.dataManagementToken !== undefined)
  			dataManagementToken = args.dataManagementToken;
  		var gatewayBackendToken: AccessToken = sGatewayBackendToken;
  		if (args.gatewayBackendToken !== undefined)
  			gatewayBackendToken = args.gatewayBackendToken;

		// only proceed if we have a data-management access token and a gateway-backend token.
		if (dataManagementToken.access_token !== "" && gatewayBackendToken.access_token !== "")
		{

			//var urlCommand: string = 'http://localhost:8080/v1/data_management/move_data?';
			var urlCommand: string = 'https://gateway.srcdev.skao.int/tannet-api/v1/data_management/move_data?';

			// token.
			urlCommand = urlCommand +	'to_storage_area_uuid=' + args.settings.toStorageAreaUUID + '&' +
							'lifetime=' + args.settings.lifetime.toString() + '&' +
							'data_management_token=' + dataManagementToken.access_token;
							
			// build authorisation parameter.
			const authorisation: string = 'Bearer ' + gatewayBackendToken.access_token;

			try
			{
				
				const apiResult = await fetch( urlCommand,	{
										method: 'POST',
										headers:	{
												'Accept': 'application/json',
												'Content-Type': 'application/json',
												'Authorization': authorisation
												},
										body: JSON.stringify( args.settings.filesToMove )
										} );
				if (apiResult.status === 201)
				{
				
					const returnedJson = await apiResult.json();

					// get job ID.
					var jobID: string = '';
					if (returnedJson.job_id !== undefined)
						jobID = returnedJson.job_id;
					
					// update the state with the job ID.
					console.log( "Submitted job id:" );
					console.log( jobID );
					
					// refresh the data-management jobs panel.
					const currentTask: CurrentTask = { taskType: TaskType.GET_DATA_MANAGEMENT_JOBS };
					taskExecutor(	{
							currentTask: currentTask,
							gatewayBackendToken: gatewayBackendToken,
							dataManagementToken: dataManagementToken
							} );
					
				}
				
				// if the return code is 401 then the tokens have expired. we should renew them.
				if (apiResult.status === 401)
				{
					const currentTask: CurrentTask =	{
										taskType: TaskType.MOVE_DATA_TO_STORAGE,
										moveDataToStorage: args.settings
										};
					renewTokens( { currentTask: currentTask } );
				}
				
			}
			catch (e)
			{
				console.log( e );
			}
		
		}
  	
  	} // moveDataToStorage

	//	-------------------------------------------------
	//
	//	find the position of the nth occurrence of a
	//	substring within a string.
	//
	//	-------------------------------------------------
  	
  	const nthIndexOf = function( args: { inputString: string, findString: string, itemNumber: number } )
  	{
  	
  		var i: number = -1;
  		var n: number = args.itemNumber;
  		while (n-- && i++ < args.inputString.length)
  		{
  			i = args.inputString.indexOf( args.findString, i );
  			if (i < 0) break;
  		}
  		
  		return i;
  	
  	} // nthIndexOf

	//	-------------------------------------------------
	//
	//	handles button click events on the main form.
	//
	//	-------------------------------------------------

	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
	{
	
		event.preventDefault();

		const button: HTMLButtonElement = event.currentTarget;
		
		// if one of the project buttons has been pressed then update the currently selected project.
		if (button.name.length > 7)
			if (button.name.slice( 0, 7 ) === "project")
			
				setSelectedProject( button.name );
			
		// if one of the tool buttons has been pressed then update the currently selected tool.
		if (button.name === "btnHome" || button.name === "btnSearchCatalog" || button.name === "btnVisualiseData" || button.name === "btnNotebook" ||
			button.name === "btnWorkflow" || button.name === "btnMyFiles" || button.name === "btnTerminal" || button.name === "btnVMs" || button.name === "btnSearchCompute" || button.name === "btnDataManagement")
			
			switch( button.name )
			{
				case "btnHome":
					setSelectedTool( ToolType.HOME );
					break;
				case "btnSearchCatalog":
					setSelectedTool( ToolType.SEARCH_CATALOG );
					break;
				case "btnSearchCompute":
					setSelectedTool( ToolType.SEARCH_COMPUTE );
					break;
				case "btnDataManagement":
					setSelectedTool( ToolType.DATA_MANAGEMENT );
					break;
				case "btnNotebook":
					setSelectedTool( ToolType.NOTEBOOK );
					break;
				case "btnVisualiseData":
					setSelectedTool( ToolType.VISUALISE_DATA );
					break;
				case "btnWorkflow":
					setSelectedTool( ToolType.WORKFLOW );
					break;
				case "btnMyFiles":
					setSelectedTool( ToolType.MY_FILES );
					break;
				case "btnTerminal":
					setSelectedTool( ToolType.TERMINAL );
					break;
				case "btnVMs":
					setSelectedTool( ToolType.VIRTUAL_MACHINE );
					break;
			}
			
		// if the login button is clicked then display or hide the pop-up login box.
		if (button.name === "btnLogin")
			
			// connect to SKA IAM and OIDC.
			redirectToLoginURL();
			
		// if the user account button is clicked then display or hide the dropdown menu.
		if (button.name === "btnUserAccount" && sUserDropdownMenuDisplayed === false)
			setUserDropdownMenuDisplayed( true );
		else
			setUserDropdownMenuDisplayed( false );
			
		// logout button from the user control dropdown menu.
		if (button.name === "btnLogout")
		{
			setUserDropdownMenuDisplayed( false );
			localStorage.removeItem( 'science_gateway_logged_in' );
			localStorage.removeItem( 'science_gateway_auth_token' );
			localStorage.removeItem( 'science_gateway_site_capabilities_token' );
			localStorage.removeItem( 'science_gateway_gateway_backend_token' );
			setLoggedIn( false );
		}
		
		// OIDC token button from the user control dropdown menu.
		if (button.name === "btnOIDCToken")
			setSelectedTool( ToolType.OIDC_TOKENS );
			
		// user preferences dialog
		if (button.name === "btnPreferences")
		{
			if (sShowPreferencesDialog === true)
				setPreferencesDialogDisplay( false );
			else
				setPreferencesDialogDisplay( true );
		}
		
		// language menu.
		if (button.name === "lstLanguage" && sLanguageDropdownMenuDisplayed === false)
			setLanguageDropdownMenuDisplayed( true );
		else
			setLanguageDropdownMenuDisplayed( false );
			
		// update the current language.
		if (button.name.length > 12)
			if (button.name.slice( 0, 12 ) === "btnLanguage_")
			{

				// get the rest of the text.
				var language: string = button.name.slice( 12 - button.name.length );

				// update the language index.
				var languageIndex: number = LANGUAGE_OPTIONS.findIndex( (element) => element.value === language );
				if (languageIndex === -1)
					languageIndex = 0;
				setLanguageIndex( languageIndex );
		
				// update the user-preferences database.
				if (sLoggedIn === true)
				{
					const settings: UserSettings = { language: language };
					const currentTask: CurrentTask =	{
										taskType: TaskType.UPDATE_SETTINGS,
										settings: settings
										}
					taskExecutor( { currentTask: currentTask } );
				}
				
				// change the language.
				i18n.changeLanguage( language );
			
			}
		
	}; // onClickHandler

	//	-------------------------------------------------
	//
	//	calls the IAM /login endpoint to get the login url, and redirect the browser.
	//
	//	-------------------------------------------------
  	
  	async function redirectToLoginURL()
  	{
		
  		// interface for parsing the returned JSON from /login.
  		interface uriResult
  		{
  			//authorization_uri: string;
  			login_url: string;
  		}
	
		try
		{
	
			// call the /login endpoint of the SKA IAM.
			//const apiResult = await fetch( 'http://localhost:8080/v1/login?redirect_uri=' + sRedirectURI,
			const apiResult = await fetch( 'https://gateway.srcdev.skao.int/tannet-api/v1/login?redirect_uri=' + sRedirectURI,
								{headers: {'Content-Type': 'application/json'}} );
			const loginURI = await apiResult.text();
			
			// parse the returned values.
			let obj: uriResult = JSON.parse( loginURI );
			
			// redirect the browser to the login uri.
			window.location.href = obj.login_url;
			
      		}
      		catch (e)
      		{
		}
  	
  	} // redirectToLoginURL

	//	-------------------------------------------------
	//
	//	renew the access tokens
	//
	//	-------------------------------------------------
  	
  	async function renewTokens( args: { currentTask: CurrentTask } )
  	{
  	
  		console.log( "App => renewTokens() fires" );
  		
  		// get the most recent refresh token. this is the one we need to refresh the others.
  		var latestRefreshToken: string = sAuthToken.refresh_token;
  		if (sGatewayBackendToken.refresh_token !== "")
  			latestRefreshToken = sGatewayBackendToken.refresh_token;
  		if (sDataManagementToken.refresh_token !== "")
  			latestRefreshToken = sDataManagementToken.refresh_token;
  		if (sSiteCapabilitiesToken.refresh_token !== "")
  			latestRefreshToken = sSiteCapabilitiesToken.refresh_token;
  	
		try
		{

  			// call the /refresh_token endpoint here.
			//const apiResult = await fetch( 'http://localhost:8080/v1/refresh_token?refresh_token=' + latestRefreshToken,
			const apiResult = await fetch( 'https://gateway.srcdev.skao.int/tannet-api/v1/refresh_token?refresh_token=' + latestRefreshToken,
								{headers: {'Content-Type': 'application/json'}} );

			if (apiResult.status === 200)
			{
			
				const returnedJson = await apiResult.json();
				console.log( returnedJson );
				
				// initialise new tokens to empty.
				var newAuthToken: AccessToken = EMPTY_TOKEN;
				var newSiteToken: AccessToken = EMPTY_TOKEN;
				var newDMToken: AccessToken = EMPTY_TOKEN;
				var newGatewayToken: AccessToken = EMPTY_TOKEN;
				
				// was the auth token returned OK ?
				if (returnedJson.auth_token_status_code === 200)
				{
				
					// get the authentication token.
					const token = returnedJson.auth_token;
					
					// store the access token so we can use it for future exchanges.
					newAuthToken = { access_token: token.access_token, token_type: token.token_type, refresh_token: token.refresh_token,
								expires_in: token.expires_in, scope: token.scope, id_token: token.id_token, expires_at: token.expires_at }
					setAuthToken( newAuthToken );
					
					// was the site-capabilities token returned OK ?
					if (returnedJson.site_capabilities_token_status_code === 200)
					{
					
						// get the site-capabilities token.
						const siteToken = returnedJson.site_capabilities_token;
						
						// store the access token so we can use it for future exchanges.
						newSiteToken = { access_token: siteToken.access_token, token_type: siteToken.token_type, refresh_token: siteToken.refresh_token,
										expires_in: siteToken.expires_in, scope: siteToken.scope, id_token: siteToken.id_token,
										expires_at: siteToken.expires_at }
						setSiteCapabilitiesToken( newSiteToken );
						
						// set cookies so that these state variables survive a refresh.
						localStorage.setItem( 'science_gateway_site_capabilities_token', JSON.stringify( newSiteToken ) );
					
					}
					
					// was the data-management token returned OK ?
					if (returnedJson.data_management_token_status_code === 200)
					{
					
						// get the data-management token.
						const dmToken = returnedJson.data_management_token;
						
						// store the access token so we can use it for future exchanges.
						newDMToken = { access_token: dmToken.access_token, token_type: dmToken.token_type, refresh_token: dmToken.refresh_token,
										expires_in: dmToken.expires_in, scope: dmToken.scope, id_token: dmToken.id_token,
										expires_at: dmToken.expires_at }
						setDataManagementToken( newDMToken );
						
						// set cookies so that these state variables survive a refresh.
						localStorage.setItem( 'science_gateway_data_management_token', JSON.stringify( newDMToken ) );
					
					}
					
					// was the gateway-backend token returned OK ?
					if (returnedJson.gateway_backend_token_status_code === 200)
					{
					
						// get the gateway-backend token.
						const gatewayToken = returnedJson.gateway_backend_token;
						
						// store the access token so we can use it for future exchanges.
						newGatewayToken = { access_token: gatewayToken.access_token, token_type: gatewayToken.token_type, refresh_token: gatewayToken.refresh_token,
										expires_in: gatewayToken.expires_in, scope: gatewayToken.scope, id_token: gatewayToken.id_token,
										expires_at: gatewayToken.expires_at }
						setGatewayBackendToken( newGatewayToken );
						
						// set cookies so that these state variables survive a refresh.
						localStorage.setItem( 'science_gateway_gateway_backend_token', JSON.stringify( newGatewayToken ) );
					
					}
					
					// set cookies so that these state variables survive a refresh.
					localStorage.setItem( 'science_gateway_auth_token', JSON.stringify( newAuthToken ) );
				
					console.log( "tokens have been refreshed" );
				
				}
				
				// now we need to finish doing whatever we were doing before a token was found to be expired.
				taskExecutor(	{
						currentTask: args.currentTask,
						gatewayBackendToken: newGatewayToken,
						siteCapabilitiesToken: newSiteToken,
						dataManagementToken: newDMToken
						} );
						
			}
		
      		}
      		catch (e)
      		{
      			console.log(e);
		}
  	
  	} // renewTokens

	//	------------------------------------------------------------
	//
	//	Use the backend API to add or update a user-access token.
	//
	//	------------------------------------------------------------
  	
  	async function saveUserToken( args: { userServiceToken: UserServiceToken, gatewayBackendToken?: AccessToken } )
  	{
		
		// choose which token to use, either the one from state or the supplied one.
		var gatewayBackendToken: AccessToken = sGatewayBackendToken;
		if (args.gatewayBackendToken !== undefined)
			gatewayBackendToken = args.gatewayBackendToken;
		if (gatewayBackendToken.access_token !== '')

			try
			{
				

				const authorisation: string = 'Bearer ' + gatewayBackendToken.access_token;
				//var url = 'http://localhost:8080/v1/set_user_token' +
				var url = 'https://gateway.srcdev.skao.int/tannet-api/v1/set_user_token' + 
						'?service_id=' + encodeURIComponent( args.userServiceToken.serviceID ) +
						'&using_token=' + (args.userServiceToken.usingToken === true ? 'Y' : 'N')
				if (args.userServiceToken.usingToken === true)
					url = url +
						'&username=' + encodeURIComponent( args.userServiceToken.username ) +
						'&user_token=' + encodeURIComponent( args.userServiceToken.userToken );

				// call the /set_user_token endpoint of the backend API.
				const apiResult = await fetch( url, { headers: {'Content-Type': 'application/json', 'Authorization': authorisation}, method: 'PUT' } );

				// if we get a 401 then the token has expired. Renew tokens.
				if (apiResult.status === 401)
				{
					const currentTask: CurrentTask =	{
										taskType: TaskType.UPDATE_USER_SERVICE_TOKEN,
										userServiceToken: args.userServiceToken
										};
					renewTokens( { currentTask: currentTask } );
				}
				
			}
			catch (e)
			{
			}
  	
  	} // saveUserToken

	//	-------------------------------------------------
	//
	//	apply new settings to the database for this user.
	//
	//	-------------------------------------------------

	async function setPreferences( args:	{
						settings: UserSettings,
						gatewayBackendToken?: AccessToken
						} )
	{
	
		// choose which gateway token to use, either the one from the state or the one supplied.
		var gatewayBackendToken: AccessToken = sGatewayBackendToken;
		if (args.gatewayBackendToken !== undefined)
			gatewayBackendToken = args.gatewayBackendToken;
		if (gatewayBackendToken.access_token !== '')
		
			try
			{

				var withParams: boolean = false;
				//var url = 'http://localhost:8080/v1/set_preferences';
				var url = 'https://gateway.srcdev.skao.int/tannet-api/v1/set_preferences';
				if (args.settings.darkMode !== undefined)
				{
					if (withParams === false)
					{
						url = url + "?";
						withParams = true;
					}
					else
						url = url + "&";
					url = url + "dark_mode=" + (args.settings.darkMode === true ? "T" : "F");
				}
				if (args.settings.language !== undefined)
				{
					if (withParams === false)
					{
						url = url + "?";
						withParams = true;
					}
					else
						url = url + "&";
					url = url + "language=" + args.settings.language;
				}

				// call the /set_preferences end point of the backend API.
				const authorisation: string = 'Bearer ' + gatewayBackendToken.access_token;
				const apiResult = await fetch( url, { headers: {'Content-Type': 'application/json', 'Authorization': authorisation}, method: 'PUT' } );
						
				// if we get a 401 then the token has expired. Renew tokens.
				if (apiResult.status === 401)
				{
					const currentTask: CurrentTask =	{
										taskType: TaskType.UPDATE_SETTINGS,
										settings: args.settings
										};
					renewTokens( { currentTask: currentTask } );
				}

			}
			catch (e)
			{
			}

	} // setPreferences
				
	//	------------------------------------------------------------
	//
	//	carry out a defined task that requires a valid access token.
	//	if the token has expired then it will be refreshed and the
	//	task will be attempted again.
	//
	//	------------------------------------------------------------
  	
  	async function taskExecutor( args:	{
  						currentTask: CurrentTask,
  						gatewayBackendToken?: AccessToken,
  						siteCapabilitiesToken?: AccessToken,
  						dataManagementToken?: AccessToken
  						} )
  	{
					
		// finish loading the user settings.
		if (args.currentTask.taskType === TaskType.LOAD_SETTINGS)
			loadUserSettings( {	gatewayBackendToken: args.gatewayBackendToken } );
			
		// finish updating the user settings.
		if (args.currentTask.taskType === TaskType.UPDATE_SETTINGS && args.currentTask.settings !== undefined)
			setPreferences( {	settings: args.currentTask.settings,
						gatewayBackendToken: args.gatewayBackendToken } );
						
		// finish updating user-service token.
		if (args.currentTask.taskType === TaskType.UPDATE_USER_SERVICE_TOKEN && args.currentTask.userServiceToken !== undefined)
			saveUserToken( {	userServiceToken: args.currentTask.userServiceToken,
						gatewayBackendToken: args.gatewayBackendToken } );
						
		// finish moving data files to storage.
		if (args.currentTask.taskType === TaskType.MOVE_DATA_TO_STORAGE && args.currentTask.moveDataToStorage !== undefined)
			moveDataToStorage( {	settings: args.currentTask.moveDataToStorage,
						gatewayBackendToken: args.gatewayBackendToken,
						dataManagementToken: args.dataManagementToken } );
						
		// get data-management jobs.
		if (args.currentTask.taskType == TaskType.GET_DATA_MANAGEMENT_JOBS)
			getDataManagementJobs(	{
						gatewayBackendToken: args.gatewayBackendToken,
						dataManagementToken: args.dataManagementToken
						} );
  	
  	} // taskExecutor

	//	-------------------------------------------------
	//
	//	toggle between using light and dark modes
	//
	//	-------------------------------------------------

	const toggleTheme = () =>
	{
	
		const newTheme = (sTheme === 'light' ? 'dark' : 'light');
		setTheme( newTheme );
		
	} // toggleTheme
	
	//	-------------------------------------------------
	//
	//	Functions that allows the child components to
	//	update the state of the App function.
	//
	//	-------------------------------------------------
	
	function updateState( args:	{
					resetSearchPosition?: boolean
					} )
	{
	
		// reset the state of the object id because a new result set has been
		// created on the Search catalogue tab.
		if (args.resetSearchPosition !== undefined)
			if (args.resetSearchPosition === true)
				setSearchPosition( [] );
	
	} // updateState

	//	-------------------------------------------------
	//
	//	The user has chosen clicked on a dataset in Aladin
	//	and selected to view the data on the Search
	//	catalogue tab.
	//
	//	-------------------------------------------------
  	
  	const viewDataFromAladinFootprint = function( args: { position: { id: string, ra: number, dec: number }[] } )
  	{
  	
  		console.log( "search-catalog.tsx - obs_publisher_did:" );
  		console.log( args.position );
  		
  		// store the object ID.
  		setSearchPosition( args.position );
  		
  		// switch the tab to the search results page.
  		setSelectedTool( ToolType.SEARCH_CATALOG );
  	
  	} // viewDataFromAladinFootprint

	//	-------------------------------------------------
	//
	//	view in particular RA and dec in Aladin Lite.
	//
	//	-------------------------------------------------
	
	function viewInAladin(	args:	{
					ra: number,
					dec: number,
					obs_publisher_did: string
					} )
	{
	
		// update Aladin Lite RA and Dec.
		//setAladinPosition( { ra: args.ra, dec: args.dec, obs_publisher_did: args.obs_publisher_did } );
		
		// switch the tab to the visualisation tab.
		setSelectedTool( ToolType.VISUALISE_DATA );
	
	} // viewInAladin
  	
  	// this code will not be executed immediately, it will be execute a short while after the page has loaded.
  	useEffect	( () =>
		  	{
			
				// get the re-direct address from the URL.
				var redirect: string = document.location.href;
				if (nthIndexOf( { inputString: redirect, findString: '/', itemNumber: 3 } ) > 0)
					redirect = redirect.slice( 0, nthIndexOf( { inputString: redirect, findString: '/', itemNumber: 3 } ) );
				setRedirectURI( redirect );
		  	
			  	// if we've been returned from logging into IAM then get the code and the state.
				const queryParameters = new URLSearchParams( window.location.search );
				const code = queryParameters.get( "code" );
	
				// if we're logged in then get the username and settings.
				if (sLoggedIn === true && sAuthToken.access_token !== '')
				{
					
					// get the username.
					getUsername( { accessToken: gatewayBackendToken } );
					
					// load the user settings.
					loadUserSettings( { gatewayBackendToken: gatewayBackendToken } );
		  	
			  		// get the list of data-management jobs.
			  		getDataManagementJobs(	{
			  					gatewayBackendToken: gatewayBackendToken,
			  					dataManagementToken: dataManagementToken
			  					} );
					
				}
				
				// otherwise, attempt to retrieve an access token from the API using the code from the url parameters.
				if (sLoggedIn === false && code !== null)
				{
				
					getAccessToken( { code: code, redirectURI: redirect } );
				
					// remove the query params from the url.
					window.history.replaceState( null, "", redirect );
				
				}
				
				console.log( 'NODE_ENV:' );
				console.log( process.env.NODE_ENV );
						
				// set the theme and language.
				//setTheme( "dark" );
				//i18n.changeLanguage( "nl" );
				
			}, []
			);
  	
		// <React.StrictMode>
		// </React.StrictMode>
	return (
		<Suspense fallback = "loading">
		<div className = "page-body" data-theme = {sTheme}>
		
			<div className = "header">
			
				<div className = "header-icon">
					<div className = "ska-logo-container-dark"><img src = {srcLogo} alt = "" width = "190px" /></div>
					<div className = "ska-logo-container-light"><img src = {srcLightLogo} alt = "" width = "190px" /></div>
				</div>
									
				{/* the horizontal icon bar contains all the tools that the user might want to use. the selected tool appears in red and all the other tools
				    in blue. the login box is pinned to the right-hand size, and appears in green */}
				<div className = "horizontal-icon-bar">

					<Tool	name = "btnHome"
						icon = {homeIcon}
						text = {t("Home")}
						onClick = {onClickHandler}
						selected = {sSelectedTool === ToolType.HOME} />
						
					<Tool	name = "btnSearchCatalog"
						icon = {magnifierIcon}
						text = {t("Search catalogue")}
						onClick = {onClickHandler}
						selected = {sSelectedTool === ToolType.SEARCH_CATALOG} />
							
					<Tool	name = "btnSearchCompute"
						icon = {monitorIcon}
						text = {t("Search compute resources")}
						onClick = {onClickHandler}
						selected = {sSelectedTool === ToolType.SEARCH_COMPUTE}
						disabled = {loggedIn === false || siteCapabilitiesToken.access_token === ""} />
						{/*disabled = {sLoggedIn === false} />*/}
						
					<Tool	name = "btnDataManagement"
						icon = {monitorIcon}
						text = {t("Data management")}
						onClick = {onClickHandler}
						selected = {sSelectedTool === ToolType.DATA_MANAGEMENT}
						disabled = {loggedIn === false || dataManagementToken.access_token === ""} />
						
					<Tool	name = "btnNotebook"
						icon = {jupyterIcon}
						text = {t("Notebook")}
						onClick = {onClickHandler}
						selected = {sSelectedTool === ToolType.NOTEBOOK}
						disabled = {sJupyterURL === ''} />
						
					<Tool	name = "btnVisualiseData"
						icon = {chartIcon}
						text = {t("Visualise data")}
						onClick = {onClickHandler}
						selected = {sSelectedTool === ToolType.VISUALISE_DATA} />
						
					{/*<Tool	name = "btnWorkflow"
							icon = {workflowIcon}
							text = {t("Workflow")}
							onClick = {onClickHandler}
							selected = {sSelectedTool === ToolType.WORKFLOW}
							disabled = {true} />*/}
							
					{/*<Tool	name = "btnMyFiles"
							icon = {folderIcon}
							text = {t("Files")}
							onClick = {onClickHandler}
							selected = {sSelectedTool === ToolType.MY_FILES}
							disabled = {true} />*/}
						
					{/*<Tool	name = "btnTerminal"
							icon = {terminalIcon}
							text = {t("Terminal")}
							onClick = {onClickHandler}
							selected = {sSelectedTool === ToolType.TERMINAL}
							disabled = {true} />*/}
							
					{/*<Tool	name = "btnVMs"
							icon = {monitorIcon}
							text = {t("VMs")}
							onClick = {onClickHandler}
							selected = {sSelectedTool === ToolType.VIRTUAL_MACHINE}
							disabled = {true} />*/}

					<div className = "flex-expander"></div>
								
					<div className = "horizontal-icon-bar-flex-column">
					
						<div style = {{ flex: '0 0 40px' }}></div>
						<UserControl	key = {sUsername}
								loggedIn = {sLoggedIn}
								username = {sUsername}
								buttonHandler = {onClickHandler}
								sUserDropdownMenuDisplayed = {sUserDropdownMenuDisplayed}
								setUserDropdownMenuDisplayed = {setUserDropdownMenuDisplayed} />
								{/*loginBoxDisplayed = {loginDisplayed} updateState={updateState}*/}
								
						<div style =	{{
								position: 'relative',
								margin: '0px 10px 0px 10px',
								display: 'flex',
								flexDirection: 'column',
								flex: '0 0 40px'
								}}>
							<button	className = "horizontal-icon-bar-language-holder"
									name = "lstLanguage"
									type = "button"
									onClick = {onClickHandler}
									data-menu-displayed = {sLanguageDropdownMenuDisplayed ? "T" : "F"}>
								<div className = "language-text">{LANGUAGE_OPTIONS[ sLanguageIndex ].label}</div>
								<div className = "flex-10px"></div>
								<img src = {LANGUAGE_OPTIONS[ sLanguageIndex ].icon} alt = "" width = "30" height = "16" />
								<div className = "flex-5px"></div>
							</button>
							<LanguageDropdownMenu	sLanguageDropdownMenuDisplayed = {sLanguageDropdownMenuDisplayed}
										setLanguageDropdownMenuDisplayed = {setLanguageDropdownMenuDisplayed}
										buttonHandler = {onClickHandler} />
						</div>
						
					</div>
							
				</div>
			
			</div>
			<div className = "white-horizontal-separator"></div>
			
			{/* the project space is everything below the tool bar */}
			<div style = {projectSpace}>
			
				{/* we have a project bar down the left-hand side so the user can switch between projects */}
				<div className = "project-bar">
					<div className = "project-button-container">
						<ProjectButton	name = "projectOne"
								icon = {planetIcon}
								onClick = {onClickHandler}
								selected = {sSelectedProject === "projectOne" || sSelectedProject === ""}
								projectName = {t("Project One")}
								description = "Project description goes here"
								pi = "PI: A.N. Other" />
						<ProjectButton	name = "projectTwo"
								icon = {dishIcon}
								onClick = {onClickHandler}
								selected = {sSelectedProject === "projectTwo"}
								projectName = {t("Project Two")}
								description = "Project description goes here"
								pi = "PI: A.N. Other" />
						<ProjectButton	name = "projectThree"
								icon = {galaxyIcon}
								onClick = {onClickHandler}
								selected = {sSelectedProject === "projectThree"}
								projectName = {t("Project Three")}
								description = "Project description goes here"
								pi = "PI: A.N. Other" />
					</div>
					<div className = "flex-expander"></div>
				</div>
			    	<div className = "white-vertical-separator"></div>
						
		
				{/* The home tab */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.HOME ? "T" : "F"}>
					<Home></Home>
				</div>
			
				{/* The search catalog tab for data discovery */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.SEARCH_CATALOG ? "T" : "F"}>
					<SearchCatalog	accessToken = {dataManagementToken.access_token !== '' ? dataManagementToken : sDataManagementToken}
							viewInAladin = {viewInAladin}
							searchPosition = {sSearchPosition}
							setSearchPosition = {setSearchPosition}
							updateState = {updateState}
							tabVisible = {sSelectedTool === ToolType.SEARCH_CATALOG}
							initiateDataManagementSearchEvent = {initiateDataManagementSearchEvent} />
				</div>
				
				{/* For debugging only - display the data on the currently logged in user */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.OIDC_TOKENS ? "T" : "F"}>
					<UserControlOIDCToken	authToken = {sAuthToken}
								siteCapabilitiesToken = {siteCapabilitiesToken.access_token !== '' ? siteCapabilitiesToken : sSiteCapabilitiesToken}
								dataManagementToken = {dataManagementToken.access_token !== '' ? dataManagementToken : sDataManagementToken}
								gatewayBackendToken = {gatewayBackendToken.access_token !== '' ? gatewayBackendToken : sGatewayBackendToken} />
				</div>
				
				{/* The search compute tab */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.SEARCH_COMPUTE ? "T" : "F"}>
					<SearchCompute	authToken = {sAuthToken}
							siteCapabilitiesToken = {siteCapabilitiesToken.access_token !== '' ? siteCapabilitiesToken : sSiteCapabilitiesToken}
							gatewayBackendToken = {gatewayBackendToken.access_token !== '' ? gatewayBackendToken : sGatewayBackendToken}
							taskExecutor = {taskExecutor}
							renewTokens = {renewTokens}
							launchNotebook = {launchNotebook}
							tabVisible = {sSelectedTool === ToolType.SEARCH_COMPUTE} />
				</div>
				
				{/* The data management tab */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.DATA_MANAGEMENT ? "T" : "F"}>
					<DataManagement	authToken = {sAuthToken}
								dataManagementToken = {dataManagementToken.access_token !== '' ? dataManagementToken : sDataManagementToken}
								siteCapabilitiesToken = {siteCapabilitiesToken.access_token !== '' ? siteCapabilitiesToken : sSiteCapabilitiesToken}
								gatewayBackendToken = {gatewayBackendToken.access_token !== '' ? gatewayBackendToken : sGatewayBackendToken}
								dataManagementJobs = {sDataManagementJobs}
								renewTokens = {renewTokens}
								getDataManagementJobs = {getDataManagementJobs}
								taskExecutor = {taskExecutor}
								jobsLoading = {sJobsLoading}
								tabVisible = {sSelectedTool === ToolType.DATA_MANAGEMENT}
								initiateSearch = {initiateDataManagementSearchUpdate} />
				</div>
				
				{/* The notebook tab */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.NOTEBOOK ? "T" : "F"}>
					<iframe	key = {sJupyterRender}
							className = "notebook"
							src = {sJupyterURL} />
				</div>
				
				{/* The visualisation tab */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.VISUALISE_DATA ? "T" : "F"}>
					<Visualisation	params = {buildVisualisationParams()} />
				</div>
				
				{/* If none of the above then we display an empty page */}
				<div	className = "section"
					data-visible = {sSelectedTool !== ToolType.SEARCH_CATALOG && sSelectedTool !== ToolType.OIDC_TOKENS  &&
								sSelectedTool !== ToolType.SEARCH_COMPUTE && sSelectedTool !== ToolType.NOTEBOOK &&
								sSelectedTool !== ToolType.VISUALISE_DATA && sSelectedTool !== ToolType.HOME &&
								sSelectedTool !== ToolType.DATA_MANAGEMENT ? "T" : "F"}>
					<div className = "empty-window"></div>
				</div>
						
				{/* display the user preferences dialog (if the state flag is set) */}
			    	{
			    		sShowPreferencesDialog &&
			    			<Preferences	showModal = {setPreferencesDialogDisplay} 
			    					setTheme = {setTheme}
			    					taskExecutor = {taskExecutor} />
			    	}		
			    	{/*<div className="white-vertical-separator"></div>*/}
			    	
			</div>
			{/*<div className="white-horizontal-separator"></div>*/}
			
		</div>
        	</Suspense>
	);
	
} // App

export default App;
