import React from 'react';
import '../../App.css';
//import CSS from 'csstype';
import './search-results.css';
import { withTranslation, WithTranslation } from "react-i18next";

// icons

// classes

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface SearchResultsFooterProps extends WithTranslation
{

	page: number;
	pageSize: number;
	pages: number;
	rows: number;
	updatePage: any;
	visible?: boolean;

} // SearchResultsFooterProps

interface SearchResultsFooterState
{

	visible: boolean;

} // SearchResultsState

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

class SearchResultsFooter extends React.Component<SearchResultsFooterProps, SearchResultsFooterState>
{

	constructor( props:SearchResultsFooterProps )
	{
		super( props );
		this.state =
		{
			visible: (props.visible !== undefined ? props.visible : true)
		};
	};

	//	-------------------------------------------------
	//
	//	button handler for onClick events.
	//
	//	-------------------------------------------------
	
	onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
	{
	
		event.preventDefault();

		const button: HTMLButtonElement = event.currentTarget;

		if (button.name === "searchResultsFirst" && this.props.page > 0)
			this.props.updatePage( {page: 0} );
		if (button.name === "searchResultsPrevious" && this.props.page > 0)
			this.props.updatePage( {page: this.props.page - 1} );
		if (button.name === "searchResultsMinus2" && this.props.page > 1)
			this.props.updatePage( {page: this.props.page - 2} );
		if (button.name === "searchResultsMinus1" && this.props.page > 0)
			this.props.updatePage( {page: this.props.page - 1} );
		if (button.name === "searchResultsPlus1" && this.props.page < this.props.pages - 1)
			this.props.updatePage( {page: this.props.page + 1} );
		if (button.name === "searchResultsPlus2" && this.props.page < this.props.pages - 2)
			this.props.updatePage( {page: this.props.page + 2} );
		if (button.name === "searchResultsNext" && this.props.page < this.props.pages - 1)
			this.props.updatePage( {page: this.props.page + 1} );
		if (button.name === "searchResultsLast" && this.props.page < this.props.pages - 1)
			this.props.updatePage( {page: this.props.pages - 1} );
				
	} // onClickHandler

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	render()
	{
	
		return	(
		
			<div className={this.state.visible === true ? "search-results-footer" : "search-results-footer-hidden"}>
				<div className="search-results-showing-count">{this.props.t("Showing results from to of", {start: this.props.rows > 0 ? (this.props.page * this.props.pageSize) + 1 : 0, end: (this.props.page+1) * this.props.pageSize > this.props.rows ? this.props.rows : (this.props.page + 1) * this.props.pageSize, max: this.props.rows}) }</div>
				<div className="horizontal-expander"></div>
				<button	className={this.props.page === 0 ? "search-results-navigation-button-disabled" : "search-results-navigation-button"}
						name="searchResultsFirst" type="button" onClick={this.onClickHandler}>≪</button>
				<button	className={this.props.page === 0 ? "search-results-navigation-button-disabled" : "search-results-navigation-button"}
						name="searchResultsPrevious" type="button" onClick={this.onClickHandler}>&lt;</button>
				<button	className="search-results-navigation-button" hidden={this.props.page <= 1} name="searchResultsMinus2" type="button"
						onClick={this.onClickHandler}>{this.props.page + -1}</button>
				<button	className="search-results-navigation-button" hidden={this.props.page === 0} name="searchResultsMinus1" type="button"
						onClick={this.onClickHandler}>{this.props.page}</button>
				<button	className="search-results-navigation-button-selected" type="button">{this.props.page + 1}</button>
				<button	className="search-results-navigation-button" hidden={this.props.page >= this.props.pages - 1} name="searchResultsPlus1"
						type="button" onClick={this.onClickHandler}>{this.props.page + 2}</button>
				<button	className="search-results-navigation-button" hidden={this.props.page >= this.props.pages - 2} name="searchResultsPlus2"
						type="button" onClick={this.onClickHandler}>{this.props.page + 3}</button>
				<button	className={this.props.page >= this.props.pages - 1 ? "search-results-navigation-button-disabled" : "search-results-navigation-button"}
						name="searchResultsNext" type="button" onClick={this.onClickHandler}>&gt;</button>
				<button	className={this.props.page >= this.props.pages - 1 ? "search-results-navigation-button-disabled" : "search-results-navigation-button"}
						name="searchResultsLast" type="button" onClick={this.onClickHandler}>≫</button>
			</div>
			
			)
		
	}

} // SearchResultsFooter
//export default SearchResultsFooter;
export default withTranslation()(SearchResultsFooter);
