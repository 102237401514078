import React, { useState, useEffect } from 'react';
import '../App.css';
import './search-catalog.css';
import CSS from 'csstype';

// icons
import arrowsIcon from '../icons/arrows.256.png';
import animatedArrowsIcon from '../icons/animated-arrows.256.gif';

// classes
import SearchCatalogDatabaseList from './search-catalog-form-database-list';
import SearchResultsInfo from './search-results-info';
import ToolButton from '../tools/tool-button';
import { useTranslation } from "react-i18next";

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	Component that generates a list of data-product types by calling
//	the API.
//
//	------------------------------------------------------------

function SearchDataProductTypesList( props:	{
						changeDataProductType: any,
						placeholderShown: boolean
						} )
{
	// translation function
	const { i18n, t } = useTranslation();

	// the list of data product types.
	const [sDataProductTypes, setDataProductTypes] = useState< { dataproduct_type: string, num_records: number }[] >( [] );

	//	------------------------------------------------------------
	//
	//	Handler for select box onChange event.
	//
	//	------------------------------------------------------------
	
	const onChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const value = event.target.value;
		
		// raise an onChange event.
		props.changeDataProductType( {dataProductType: value} );
				
	} // onChange

	//	------------------------------------------------------------
	//
	//	Load the data-product types from the API
	//
	//	------------------------------------------------------------
	
	async function loadDataProductTypes()
	{
							
		//var urlCommand: string =	'http://localhost:8080/v1/data/get_product_types';
		var urlCommand: string =	'https://gateway.srcdev.skao.int/tannet-api/v1/data/get_product_types';

		try
		{
		
			const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}} );
			
			if (apiResult.status === 200)
			{

				const returnedJson = await apiResult.json();

				// get data-product types list.
				var dataProductTypesList: any = [];
				if (returnedJson.result !== undefined)
					dataProductTypesList = returnedJson.result;

				// update the state with the list of returned data-product types.
				setDataProductTypes( dataProductTypesList );
			
			}
			
		}
		catch (e)
		{
		}
	
	} // loadDataProductTypes
  	
  	// this code will not be executed immediately, it will be execute a short while after the component has loaded.
  	useEffect	( () =>
		  	{
		  	
		  		// get the data-product types.
		  		loadDataProductTypes();
				
			}, []
			);
	
	{/* return the listbox */}
	return	(
	    	
	    	<select name = "lstDataProductTypes" className = "data-product-types-listbox" multiple = {false} onChange = {onChange} data-placeholder-shown = {props.placeholderShown ? "T" : "F"}>
	    		<option hidden selected value = "">{t( "Select data-product type" )}</option>
	    		<option label = "All" value = "<all>"> All </option>
	    		{
	    			sDataProductTypes.map
	    			(
	    				item =>
	    				(
	    				<option	label = {item.dataproduct_type !== '' ? item.dataproduct_type : '<none>'}
	    						value = {item.dataproduct_type}>
	    					{item.dataproduct_type !== '' ? item.dataproduct_type : '<none>'}
	    				</option>
	    				)
	    			)
	    		}
		</select> 
		
		)

} // SearchDataProductTypesList

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function SearchCatalogForm( props:	{
							addSearchResultsTab: any,
							tabVisible: boolean,
							searchPosition: { ra: number, dec: number } | undefined,
							setSearchPosition: any,
							searchFOV: number | undefined,
							setSearchFOV: any,
							aladinPosition: { ra: number, dec: number } | undefined,
							setAladinPosition: any,
							aladinFOV: number | undefined,
							setAladinFOV: any
							} )
{

	// translation function
	const { i18n, t } = useTranslation();
  	
  	// current source name.
  	const [sSourceName, setSourceName] = useState<string>( '' );
  	const [sResolverText, setResolverText] = useState<string>("Uses SIMBAD name resolver");
  	const [sCurrentlyResolving, setCurrentlyResolving] = useState<boolean>( false );
	
  	// dataset select box.
  	const [sDatasetValue, setDatasetValue] = useState<string>( '' );
  	
  	// input boxes.
  	const [sRAValue, setRAValue] = useState<string>( "" );
  	const [sDecValue, setDecValue] = useState<string>( "" );
  	const [sRadiusValue, setRadiusValue] = useState<string>( "" );
  	
  	// data-product type.
  	const [sDataProductType, setDataProductType] = useState< string | undefined >( undefined );
  	
  	// filter box maximised, or not?
  	const [sFilterMaximised, setFilterMaximised] = useState<boolean>( true );

	//	-------------------------------------------------
	//
	//	function that handles a change in the selected data-product
	//	type on the search form
	//
	//	-------------------------------------------------

	const dataProductTypeOnChangeHandler = function( args: {dataProductType: string | undefined } )
	{
		setDataProductType( args.dataProductType );

	} // dataProductTypeOnChangeHandler

	//	------------------------------------------------------------
	//
	//	Handler for changes to the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  		
  		// update the state for any input boxes that have been changed.
  		if (inputBox.name === "sourceNameSearch")
  			setSourceName( inputBox.value );
  			
  		// update changes to the ra, dec, and fov input boxes.
  		if (inputBox.name === "raSearch")
  			setRAValue( inputBox.value );
  		if (inputBox.name === "decSearch")
  			setDecValue( inputBox.value );
  		if (inputBox.name === "radiusSearch")
  			setRadiusValue( inputBox.value );
	  	
  	} // inputHandler

	//	------------------------------------------------------------
	//
	//	Restrict input box entry to numbers only, plus . , + -
	//
	//	------------------------------------------------------------
  	
  	const numericKeysOnly = (event: React.KeyboardEvent<HTMLInputElement>) =>
  	{
  	
  		if ((event.charCode < 48 || event.charCode > 57) && (event.charCode < 43 || event.charCode > 46))
  			event.preventDefault();
  	
  	} // numericKeysOnly

	//	------------------------------------------------------------
	//
	//	Handler for lost-focus events on the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const onBlurEvent = (event: React.FocusEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  	
  		if (inputBox.name === "raSearch" || inputBox.name === "decSearch")
  		{
		
			// convert ra and dec into numeric value.
			let raNumeric: number = 0.0;
			let decNumeric: number = 0.0;
			
			try
			{
				raNumeric = Number( sRAValue );
				decNumeric = Number( sDecValue );
			}
			catch (e)
			{
			}
  			const newPosition: { ra: number, dec: number } = { ra: raNumeric, dec: decNumeric };
  			props.setSearchPosition( newPosition );
  			 
  		}
  		if (inputBox.name === "radiusSearch")
  		{
		
			// convert fov into numeric value.
			let fovNumeric: number = 0.0;
			
			try
			{
				fovNumeric = Number( inputBox.value );
			}
			catch (e)
			{
			}
  			
  			// update state.
  			if (fovNumeric > 0)
	  			props.setSearchFOV( fovNumeric );
  			 
  		}
  	
  	} // onBlurEvent

	//	------------------------------------------------------------
	//
	//	Handler for button onClick events.
	//
	//	------------------------------------------------------------
	
	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
	{

		const button: HTMLButtonElement = event.currentTarget;
		
		// resolve the name that has been entered against an online database.
		if (button.name === "resolveName")
			resolveObjectName();
			
		// minimise/maximise the filter box if the user clicks minimise/maximise.
		if (button.name === "minimiseFilter")
			setFilterMaximised( false );
		if (button.name === "maximiseFilter")
			setFilterMaximised( true );
			
		// if we click a search button on the search form then open a new results tab.
		if (button.name === "searchData")
		{
		
			// convert ra, dec, & radius into numeric value.
			var raNumeric: number = 0.0;
			var decNumeric: number = 0.0;
			var radiusNumeric: number = 0.0;
			
			try
			{
				raNumeric = Number( sRAValue );
				decNumeric = Number( sDecValue );
				radiusNumeric = Number( sRadiusValue );
			}
			catch (e)
			{
			}
		
			// add a new search results tab.
			props.addSearchResultsTab(	{
							datasetValue: sDatasetValue,
							raValue: raNumeric,
							decValue: decNumeric,
							radiusValue: radiusNumeric,
							obsPublisherDidValue: '',
							dataProductType: sDataProductType
							} );
			
		}
				
	} // onClickHandler

	//	------------------------------------------------------------
	//
	//	An asynchronous function that resolves a name against
	//	an online database, such as NED, SIMBAD or Sesame.
	//
	//	------------------------------------------------------------
  	
  	const resolveObjectName = async() =>
  	{
  	
  		// update the icon to a animated gif.
  		setCurrentlyResolving( true );
	
		try
		{
		
			//const apiResult = await fetch( 'http://localhost:8080/v1/resolve?source_name=' + sSourceName.toUpperCase() );
			const apiResult = await fetch( 'https://gateway.srcdev.skao.int/tannet-api/v1/resolve?source_name=' + sSourceName.toUpperCase() );
			
			// status is 200 if we found the source, 400 if it wasn't found, or 500 if the query wasn't well formed.
			if (apiResult.status === 200)
			{
				
				const returnedJson = await apiResult.json();
		
				// convert ra, & dec into numeric value.
				var raNumeric: number = 0.0;
				var decNumeric: number = 0.0;
				
				try
				{
					raNumeric = Number( returnedJson.ra );
					decNumeric = Number( returnedJson.dec );
				}
				catch (e)
				{
				}
				
				// update position in Aladin.
	  			const newPosition: { ra: number, dec: number } = { ra: raNumeric, dec: decNumeric };
	  			props.setSearchPosition( newPosition );
		  			
		  		// update input boxes.
		  		setRAValue( returnedJson.ra );
		  		setDecValue( returnedJson.dec );
		  		
		  		setResolverText( returnedJson.description );
			
			}
	  				
			else if (apiResult.status === 404)
				setResolverText( 'Could not resolve source' );
			
      		}
      		catch (e)
      		{
			console.log(e);
		}
		
		// return the icon to a non-animated one.
		setCurrentlyResolving( false );
  	
  	} // resolveObjectName

	//	------------------------------------------------------------
	//
	//	Check if the search positions have changed in Aladin, and
	//	if so update the values in the search boxes.
	//
	//	------------------------------------------------------------
  	
  	function updateSearchPositions()
  	{
  	
  		// convert the current search positions into numeric values.
		var raNumeric: number = 0.0;
		var decNumeric: number = 0.0;
		var fovNumeric: number = 0.0;
		
		try
		{
			raNumeric = Number( sRAValue );
			decNumeric = Number( sDecValue );
			fovNumeric = Number( sRadiusValue );
		}
		catch (e)
		{
		}
		
		// get differences between RA and Dec.
		var raDifference: number = 0.0;
		var decDifference: number = 0.0;
		if (props.aladinPosition !== undefined)
		{
			raDifference = Math.abs( raNumeric - props.aladinPosition.ra );
			while (raDifference > 180)
				raDifference = Math.abs( raDifference - 360.0 );
			decDifference = Math.abs( decNumeric - props.aladinPosition.dec );
			while (decDifference > 180)
				decDifference = Math.abs( decDifference - 360.0 );
		}
  		
  		// check if these values have changed significantly, and update the state.
  		if (props.aladinPosition !== undefined)
  		{
  			if (Math.abs( raDifference ) > 0.00001)
				setRAValue( props.aladinPosition.ra.toString() );
	  		if (Math.abs( decDifference ) > 0.00001)
				setDecValue( props.aladinPosition.dec.toString() );
	  		props.setAladinPosition( undefined );
  		}
  		if (props.aladinFOV !== undefined)
  		{
	  		if (Math.abs( fovNumeric - props.aladinFOV ) > 0.00001)
	  			setRadiusValue( props.aladinFOV.toString() );	
	  		props.setAladinFOV( undefined );
	  	}
  	
  	} // updateSearchPositions
  	
  	// update the ra, dec, and radius positions if they have changed in Aladin.
  	updateSearchPositions();
	
	{/* main search form */}
	return	(
	    		
	    	<div	style = {{ flex: '0 0 auto', height: 'auto', display: 'flex', flexDirection: 'column' }}>
	    	
		    	<button	className = "minimised-filter"
		    			name = "maximiseFilter"
		    			type = "button"
		    			title = "Maximise filter"
		    			onClick = {onClickHandler}
		    			data-maximised = {sFilterMaximised === true ? "T" : "F"} >
				<div className = "flex-15px"></div>
				<div className = "maximise">&raquo;</div>
				<div className = "flex-row">
					<div className = "rotated-text-box">Filter</div>
			    	</div>
		    	</button>
		    	
	    		<div	className = "search-form-visible"
	    			data-maximised = {sFilterMaximised === true ? "T" : "F"} >
		    	    	<div className = "flex-15px"></div>
		    	    	<div className = "flex-row">
		    			<div className = "search-catalog-form-title">{t( "Filter" )}</div>
		    			<div className = "flex-expanding"></div>
		    			<button	className = "minimise"
		    					name = "minimiseFilter"
		    					type = "button"
		    					title = "Minimise filter"
		    					onClick = {onClickHandler}>&laquo;</button>
		    			<div style = {{ flex: '0 0 15px' }}></div>
	    			</div>
		    	    	<div className = "flex-15px"></div>
	    			<div className = "source-name-search-box-horizontal">
	    				<div className = "source-name-input-container">
	    					<input	className = "input-field-source-name"
	    						type = "text"
	    						name = "sourceNameSearch"
	    						onChange = {inputHandler}
							maxLength = {30}
	    						placeholder = {t("Source name")}></input>
	    				</div>
	    	    			<div className = "flex-10px"></div>
	    	    			<div className = "flex-fixed">
		    	    			<ToolButton	key = {t("Resolve") + (props.tabVisible ? 't' : 'f')}
		    	    					name = "resolveName"
		    	    					onClick = {onClickHandler}
		    	    					icon = {sCurrentlyResolving === true ? animatedArrowsIcon : arrowsIcon}
		    	    					text = {t("Resolve")} />
		    	    		</div>
	    	    		</div>
		    	    	<div className = "flex-10px"></div>
	    	    		<div className = "resolver-text-box">{sResolverText}</div>
	    			<div className = "flex-20px"></div>
	    			<div className = "source-location-search-box">
	    				<div className = "source-location-search-box-ra-dec">
	    					<div className = "flex-expanding">
							<input	className = "input-field-ra-dec-rad"
								type = "text"
								name = "raSearch"
								onKeyPress = {numericKeysOnly}
								onChange = {inputHandler}
								onBlur = {onBlurEvent}
								maxLength = {20}
								value = {sRAValue}
								placeholder = {t("RA (degrees)")}></input>
						</div>
		    	    			<div className = "flex-10px"></div>
	    					<div className = "flex-expanding">
							<input	className = "input-field-ra-dec-rad"
								type = "text"
								name = "decSearch"
								onKeyPress = {numericKeysOnly}
								onChange = {inputHandler}
								onBlur = {onBlurEvent}
								maxLength = {20}
								value = {sDecValue}
								placeholder = {t("DEC (degrees)")}></input>
						</div>
					</div>
	    	    			<div className = "flex-15px"></div>
					<div className = "source-location-search-box-rad">
						<input	className = "input-field-ra-dec-rad"
							type = "text"
							name = "radiusSearch"
							onKeyPress = {numericKeysOnly}
							onChange = {inputHandler}
							onBlur = {onBlurEvent}
							maxLength = {20}
							value = {sRadiusValue}
							placeholder = {t("Radius (degrees)")}></input>
					</div>
				</div>
	    			<div className = "flex-15px"></div>
				<div className = "search-form-data-product-type">
					<SearchDataProductTypesList	changeDataProductType = {dataProductTypeOnChangeHandler}
									placeholderShown = {sDataProductType === undefined} />
				</div>
	    			<div className = "flex-40px"></div>
				<div className = "search-catalog-form-buttons">
					<ToolButton	key = {t("Search") + (props.tabVisible ? 't' : 'f')}
							name = "searchData"
							onClick = {onClickHandler}
							tooltip = "Submit search with these parameters"
							text = {t("Search")}
							primary = {true} />
				</div>
	    			<div className = "flex-10px"></div>
			</div>
		
		</div>
		
		)

} // SearchCatalogForm
