import React from 'react';
import '../App.css';
import './user-control.css';
import CSS from 'csstype';
import { useTranslation } from "react-i18next";

// classes
import { HideDropdownMenu } from '../functions';

// images
import oidcIcon from '../icons/oidc.256.png';
import magnifierIcon from '../icons/magnifier.512.png';
import userAccountIcon from '../icons/user.512.png';
import preferencesIcon from '../icons/preferences.512.png';
import logoutIcon from '../icons/logout.512.png';

// classes

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function UserControlDropdownMenu( props:	{
								sUserDropdownMenuDisplayed: boolean,
								setUserDropdownMenuDisplayed: any,
								buttonHandler: any
								} )
{

	// multi-language support
	const { i18n, t } = useTranslation();

	// function that monitors for mouse clicks. we need to add {ref} to the DIV element of the dropdown menu.
	const { ref } = HideDropdownMenu(	{
						sDropdownDisplayed: props.sUserDropdownMenuDisplayed,
						setDropdownDisplayed: props.setUserDropdownMenuDisplayed
						} );

	//	------------------------------------------------------------
	//
	//	A HTML component that renders a single menu item on the
	//	dropdown menu.
	//
	//	------------------------------------------------------------

	function MenuItem( args: { name: string, text: string, icon?: string, onClick: any } )
	{

		return	(
		
			<button name={args.name} className="menu-button" onClick={args.onClick}>
				<div className="menu-button-text">{args.text}</div>
				<div className="menu-button-image">
					<img src={args.icon} alt="" width="24" height="24" />
				</div>
			</button>
		
			)

	} // MenuItem

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------

	const userDropdownPopup: CSS.Properties =
  			{
			display: (props.sUserDropdownMenuDisplayed === true ? 'block' : 'none'),
			position: 'absolute',
			top: '100%',
			right: '3%',
			border: 'none',
			zIndex: '9'
  			}

	return	(

		<div	ref = {ref}
			style = {userDropdownPopup}>
			<div className="user-dropdown-menu">
				{MenuItem( {name: "btnOIDCToken", text: i18n.t("View OIDC Token"), icon: oidcIcon, onClick: props.buttonHandler} )}
				{/*this.MenuItem( {name: "btnSearchSiteCapabilities", text: i18n.t("Search Site Capabilities"), icon: magnifierIcon, onClick: this.props.buttonHandler} )*/}
				{MenuItem( {name: "btnProfile", text: i18n.t("User Profile"), icon: userAccountIcon, onClick: props.buttonHandler} )}
				{MenuItem( {name: "btnPreferences", text: i18n.t("Preferences"), icon: preferencesIcon, onClick: props.buttonHandler} )}
				{MenuItem( {name: "btnLogout", text: i18n.t("Logout"), icon: logoutIcon, onClick: props.buttonHandler} )}
			</div>
		</div>

		)

} // UserControlDropdownMenu
