import React, { useState } from 'react';
import '../App.css';
import './tool.css';
import CSS from 'csstype';

// images

// classes
import { CheckOverflow } from '../functions';
import { ShortenText } from '../functions';

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function ToolButton( props:	{
						name: string,
						icon?: string,
						disabledIcon?: string,
						text: string,
						onClick: any,
						tooltip?: string,
						disabled?: boolean,
						primary?: boolean
						} )
{

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	// the text to display in the tool.
	const [sToolText, setToolText] = useState< string >( props.text );
	const [sToolTextWithoutDots, setToolTextWithoutDots] = useState< string >( props.text );

	// check the DIV element to see if the text has overflowed.
	const monitorDivForOverflow = React.useRef<HTMLDivElement | null>( null );
	
	// remove one character at a time from the text until it no longer causes an overflow.
	if (CheckOverflow( { ref: monitorDivForOverflow, text: sToolText } ) === true)
		ShortenText( { text: sToolTextWithoutDots, setText: setToolText, setTextWithoutDots: setToolTextWithoutDots } );

	// do we need to display an icon or not?
	if (props.icon !== undefined)
		return	(
			<button	name = {props.name}
					type = "button"
					className = "button-with-icon"
					onClick = {props.onClick}
					data-primary = {props.primary ? 'T' : 'F'} >
					
				<div	className = "button-icon-container">
					<img	src = {props.icon}
						alt = ""
						width = "30"
						height = "30" />
				</div>
				
				<div	className = "button-text-container"
					style = {{overflow: 'auto'}}
					ref = {monitorDivForOverflow} >
					
					<div className = "text-container-inner">{sToolText}</div>
					
				</div>
				
			</button>
			);
	else
		return	(
			<button	name = {props.name}
					type = "button"
					title = {props.tooltip}
					className = "button"
					onClick = {props.onClick}
					data-disabled = {props.disabled ? 'T' : 'F'}
					data-primary = {props.primary ? 'T' : 'F'} >
			
				<div	className = "button-text-container"
					style = {{overflow: 'auto'}}
					ref = {monitorDivForOverflow}>
				
					<div className = "text-container-inner">{sToolText}</div>
					
				</div>
				
			</button>
			);

} // ToolButton
