import React, { useState } from 'react';
import '../App.css';
import './tool.css';
import CSS from 'csstype';

// images
import closeIcon from '../icons/close.254.png';

// classes
import { CheckOverflow } from '../functions';
import { ShortenText } from '../functions';

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function ToolSmall( props:	{
						name: string,
						text: string,
						onClick: any,
						withClose?: boolean,
						closeid?: string,
						withNumber?: boolean,
						count?: number,
						selected?: boolean,
						tabWidth?: number,
						left?: boolean,
						right?: boolean,
						empty?: boolean,
						displayDropdown?: boolean,
						dropdown?: any,
						disabled?: boolean,
						hidden?: boolean
						} )
{

	//	------------------------------------------------------------
	//
	//	A dropdown menu to hold extra tabs
	//
	//	------------------------------------------------------------

	function DropdownTool()
	{
	
		// get optional parameters.
		var tabWidth: number = 131;
		if (props.tabWidth !== undefined)
			tabWidth = props.tabWidth;	

		return	(
		
			<div	className = "button-small-container"
				data-dropdown = "T"
				style = {{flexBasis: tabWidth}} >
				
				<button	className = "button-small"
						name = {props.name}
						type = "button"
						onClick = {props.onClick}
						style = {{width: '100%'}}
						data-selected = "F"
						data-left = {props.left === true ? "T" : "F"}
						data-right = {props.right === true ? "T" : "F"}
						data-colour = {props.disabled === true ? "F" : "T"} >
				
					<div	style = {{ margin: '0px 4px 0px 4px', width: '22px', height: '22px' }}
						className = "button-text-container"
						data-empty = "T"
						data-display-dropdown = {props.displayDropdown === true ? "T" : "F"}
						data-disabled = {props.disabled === true ? "T" : "F"} >
						{props.text}
					</div>
					
				</button>
				
				{
				props.dropdown
				}
				
			</div>
			
			)

	} // DropdownTool

	//	------------------------------------------------------------
	//
	//	A empty section of the toolbar.
	//
	//	------------------------------------------------------------

	function EmptyToolSmall()
	{
	  			
	  	if (props.hidden === true)
	  	{
	  	
	  		return	(
	  			<></>
	  			)
	  	
	  	}
	  	else
	  	{
	
			return	(
			
				<div	className = "button-small-container"
					data-empty = "T">
				
					<button	className = "button-small"
							name = {props.name}
							type = "button"
							data-empty = "T"
							data-selected = "F"
							data-left = {props.left === true ? "T" : "F"}
							data-right = {props.right === true ? "T" : "F"} >
					
						<div	style = {{ margin: '0px 4px 0px 4px', width: '22px', height: '22px' }}
							className = "button-text-container"
							data-empty = "T"
							data-display-dropdown = {props.displayDropdown === true ? "T" : "F"} >
						</div>
						
					</button>
					
				</div>
				
				)
					
	  	}

	} // EmptyToolSmall

	//	------------------------------------------------------------
	//
	//	A small non-highlighted tool button with a close button.
	//
	//	------------------------------------------------------------

	function ToolSmallWithClose()
	{
	
		// the text to display in the tool.
		const [sToolText, setToolText] = useState< string >( props.text );
		const [sToolTextWithoutDots, setToolTextWithoutDots] = useState< string >( props.text );
	
		// check the DIV element to see if the text has overflowed.
		const monitorDivForOverflow = React.useRef<HTMLDivElement | null>( null );
		
		// remove one character at a time from the text until it no longer causes an overflow.
		if (CheckOverflow( { ref: monitorDivForOverflow, text: sToolText } ) === true)
			ShortenText( { text: sToolTextWithoutDots, setText: setToolText, setTextWithoutDots: setToolTextWithoutDots } );
	
		var tabWidth: number = 131;
		if (props.tabWidth !== undefined)
			tabWidth = props.tabWidth;

		return	(
		
			<div	className = "button-small-container"
				style = {{flexBasis: tabWidth, overflow: 'auto'}}
				ref = {monitorDivForOverflow} >
				
				<button	name = {props.name}
						type = "button"
						className = "button-small"
						style = {{width: '100%'}}
						data-selected = {props.selected ? "T" : "F"}
						data-left = {props.left === true ? "T" : "F"}
						data-right = {props.right === true ? "T" : "F"}
						disabled = {props.disabled}
						onClick = {props.disabled ? undefined : props.onClick} >
						
					<div	className = "button-text-container"
						data-disabled = {props.disabled ? "T" : "F"}
						data-align = "L" >
						
						<div className = "text-container-inner">{sToolText}</div>
						
					</div>
					
					<button	name = {props.closeid}
							type = "button"
							className = "button-close"
							onClick = {props.disabled ? undefined : props.onClick} >
							
						<img	src = {closeIcon}
							alt = ""
							width = "20"
							height = "20" />
							
					</button>
					
				</button>
				
			</div>
			
			)

	} // ToolSmallWithClose

	//	------------------------------------------------------------
	//
	//	A small non-highlighted tool button, with a count.
	//
	//	------------------------------------------------------------

	function ToolSmallWithNumber()
	{
	
		// the text to display in the tool.
		const [sToolText, setToolText] = useState< string >( props.text );
		const [sToolTextWithoutDots, setToolTextWithoutDots] = useState< string >( props.text );
	
		// check the DIV element to see if the text has overflowed.
		const monitorDivForOverflow = React.useRef<HTMLDivElement | null>( null );
		
		// remove one character at a time from the text until it no longer causes an overflow.
		if (CheckOverflow( { ref: monitorDivForOverflow, text: sToolText } ) === true)
			ShortenText( { text: sToolTextWithoutDots, setText: setToolText, setTextWithoutDots: setToolTextWithoutDots } );

		return	(
			
			<div	className = "button-wide-container"
				ref = {monitorDivForOverflow} >
			
				<button	name = {props.name}
						type = "button"
						className = "button-wide"
						data-selected = {props.selected ? "T" : "F"}
						data-left = {props.left === true ? "T" : "F"}
						data-right = {props.right === true ? "T" : "F"}
						onClick = {props.disabled ? undefined : props.onClick}
						disabled = {props.disabled} >
				
					<div	className = "button-text-container"
						data-disabled = {props.disabled ? "T" : "F"}
						data-align = "L">
						
						<div className = "text-container-inner">{props.text}</div>
						
					</div>
					
					<div	className = "button-number-container"
						data-selected = {props.selected ? "T" : "F"}>{props.count}</div>
					
				</button>
				
			</div>
			
			)

	} // ToolSmallWithNumber

	//	------------------------------------------------------------
	//
	//	A small tool button without a close button.
	//
	//	------------------------------------------------------------

	function ToolSmallWithoutCloseOrNumber()
	{
	
		var tabWidth: number = 131;
		if (props.tabWidth !== undefined)
			tabWidth = props.tabWidth;

		return	(
			
			<div	className = "button-small-container"
				style = {{flexBasis: tabWidth}} >
			
				<button	name = {props.name}
						type = "button"
						className = "button-small"
						style = {{width: tabWidth}}
						data-selected = {props.selected ? "T" : "F"}
						data-left = {props.left === true ? "T" : "F"}
						data-right = {props.right === true ? "T" : "F"}
						onClick = {props.disabled ? undefined : props.onClick}
						disabled = {props.disabled} >
				
					<div	className = "button-text-container"
						data-disabled = {props.disabled ? "T" : "F"}
						data-align = "L">
						
						<div className = "text-container-inner">{props.text}</div>
						
					</div>
					
				</button>
				
			</div>
			
			)

	} // ToolSmallWithoutCloseOrNumber

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	// get the display-dropdown flag.
	var displayDropdown: boolean = false;
	if (props.displayDropdown !== undefined)
		displayDropdown = props.displayDropdown;
	
	// choose which tool to render.
	if (displayDropdown === true)
		return	(
			<DropdownTool/>
			)
	else if (props.empty === true)
		return	(
			<EmptyToolSmall/>
			)
	else if (props.withClose === true)
  		return	(
  			<ToolSmallWithClose/>
  			)
  	else if (props.withNumber === true)
  		return	(
  			<ToolSmallWithNumber/>
  			)
  	else
  		return	(
  			<ToolSmallWithoutCloseOrNumber/>
  			);

} // ToolSmall
