import React from 'react';
import '../App.css';
import './user-control.css';
import CSS from 'csstype';

// images
import padlockIcon from '../icons/padlock.512.png';
import userAccountIcon from '../icons/user.512.png';

// classes
import Tool from '../tools/tool';
//import UserControlLoginBox from './user-control-login-box';
import UserControlDropdownMenu from './user-control-dropdown-menu';

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface UserControlProps
{

	//loginBoxDisplayed: boolean;
	buttonHandler: any;
	loggedIn: boolean;
	username: string;
	sUserDropdownMenuDisplayed: boolean;
	setUserDropdownMenuDisplayed: any;

} // UserControlProps

interface UserControlState
{

	username: string;
	initials: string;

} // UserControlState

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default class UserControl extends React.Component<UserControlProps, UserControlState>
{

	constructor( props:UserControlProps )
	{
	
		super( props );
		
		// loop over all the characters of the username to extract the initials.
		let initials: string = "";
		let spaceLast: boolean = true;
		if (props.username !== null)
			for ( let index = 0; index < props.username.length; index++ )
			{
				if (spaceLast === true && initials.length < 3)
					initials += props.username.slice( index, index + 1 );
				spaceLast = (props.username.slice( index, index + 1 ) === " ");
			}
		
		this.state =
		{
			username: props.username !== null ? props.username : 'Unknown User',
			initials: initials
		};
	};

	//	------------------------------------------------------------
	//
	//	Displays the login button with a different name depending
	//	upon whether we're logged in or not.
	//
	//	------------------------------------------------------------

	UserAccountButton( args: { loggedIn: boolean, buttonHandler: any } )
	{

		if (args.loggedIn === false)
			return	(
					<button className="user-control-login" name="btnLogin" type="button" onClick={args.buttonHandler}>
						<div className="user-control-login-text">Log in</div>
						<div className="user-control-login-icon"><img src={padlockIcon} alt="" width="16px"></img></div>
						{/*<div className="user-control-login-dropdown">{this.props.userDropdownMenuDisplayed ? "ᐱ" : "ᐯ"}</div>*/}
					</button>
				)
		else
			return	(
					<button className="user-control-login" name="btnUserAccount" type="button" onClick={args.buttonHandler}>
						<div className="user-control-login-text">{this.state.username}</div>
						<div className="user-control-login-initials">{this.state.initials}</div>
						<div className="user-control-login-dropdown">{this.props.sUserDropdownMenuDisplayed ? "ᐱ" : "ᐯ"}</div>
					</button>
				)

	} // UserAccountButton

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	render()
	{
	
		return	(
			<div className = "user-control">
				<div className = "flex-expander"></div>
				{
					this.UserAccountButton(	{
									loggedIn: this.props.loggedIn,
									buttonHandler: this.props.buttonHandler
									} )
				}
				<UserControlDropdownMenu	sUserDropdownMenuDisplayed = {this.props.sUserDropdownMenuDisplayed}
								setUserDropdownMenuDisplayed = {this.props.setUserDropdownMenuDisplayed}
								buttonHandler = {this.props.buttonHandler} />
				<div style = {{flex: '0 0 8px'}} />
				{/*<UserControlLoginBox displayed={this.props.loginBoxDisplayed === true} buttonHandler={this.props.buttonHandler} />*/}
			</div>
			)
		
	}

} // UserControl
