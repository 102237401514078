import React from 'react';
import '../App.css';
import './search-compute.css';
import CSS from 'csstype';
import { AccessToken } from '../types';
import { withTranslation, WithTranslation } from "react-i18next";

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface ServiceTypesListProps extends WithTranslation
{

	changeServiceType: any;
	placeholderShown: boolean;
	siteCapabilitiesAccessToken: AccessToken;
	gatewayBackendAccessToken: AccessToken;

} // ServiceTypesListProps

interface ServiceTypesListState
{

	serviceTypesList: string[];

} // ServiceTypesListState

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

class SearchComputeServiceTypesList extends React.Component<ServiceTypesListProps, ServiceTypesListState>
{

	constructor( props:ServiceTypesListProps )
	{
		super( props );
		this.state =
		{
			serviceTypesList: []
		};
	};

	async componentDidMount()
	{

		// only proceed if we have a site-capabilities access token.
		if (this.props.siteCapabilitiesAccessToken.access_token !== "")
		{
							
			//var urlCommand: string =	'http://localhost:8080/v1/site_capabilities/list_service_types?';
			var urlCommand: string =	'https://gateway.srcdev.skao.int/tannet-api/v1/site_capabilities/list_service_types?';
			
			// add site-capabilities access token.
			urlCommand = urlCommand +	'token=' + this.props.siteCapabilitiesAccessToken.access_token;

			try
			{
			
				const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}} );


				if (apiResult.status === 200)
				{

					const returnedJson = await apiResult.json();

					// get services list.
					var serviceTypesList: any = [];
					if (returnedJson.services !== undefined)
						serviceTypesList = returnedJson.services;

					// update the state with the list of returned service types.
					this.setState( {serviceTypesList: serviceTypesList} );
				
				}
				
			}
			catch (e)
			{
			}
		
		}
		
	} // componentDidMount

	//	------------------------------------------------------------
	//
	//	Handler for select box onChange event.
	//
	//	------------------------------------------------------------
	
	onChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const value = event.target.value;
		
		// raise an onChange event.
		this.props.changeServiceType( {serviceType: value} );
				
	} // onChange
	
	render()
	{
	
		{/* main search form */}
		return	(
		    	
		    	<select name="lstServiceTypes" className="service-types-listbox" multiple={false} onChange={this.onChange} data-placeholder-shown={this.props.placeholderShown ? "T" : "F"}>
		    		<option hidden selected value="">{this.props.t("Select service type")}</option>
		    		<option label="All" value="all"> All </option>
		    		{this.state.serviceTypesList.map( item => ( <option label={item} value={item}> {item} </option> ) )}
			</select> 
			
			)
			
	}

} // SearchComputeServiceTypesList

export default withTranslation() (SearchComputeServiceTypesList);
