/*
This is a modified version of the code found at
https://dev.to/franciscomendes10866/how-to-create-a-modal-in-react-3coc
*/

import React from 'react'
import './preferences.css'
import Collapsible from './collapsible'
import { useTranslation } from "react-i18next";

// types
import { AccessToken } from '../types';

// types relating to tasks.
import { UserSettings } from '../tasks';
import { CurrentTask } from '../tasks';
import { TaskType } from '../tasks';

/*
List of props passed to the ThemeCard
*/
interface ThemeCardProps
{
  name: string
  textColour: string
  headerBackground: string
  bodyBackground: string
  onClick: any
}

/*
Displays a graphical example of what the theme will look like
ThemeCard taken from https://css-tricks.com/theming-and-theme-switching-with-react-and-styled-components/
*/
const ThemeCard = (props:ThemeCardProps) => {
  const { i18n, t } = useTranslation();
  return (
    <div className="themecard" style={{backgroundColor: props.bodyBackground, color: props.textColour, borderColor: props.headerBackground}}>
      <div className="themecard-header" style={{backgroundColor: props.headerBackground}}>
	<text>{t("Theme")}</text>
      </div>
      <div className="themecard-body">
        <text>{t("Click on the button to set this theme")}</text>
        <button className="themecard-button" style={{backgroundColor: props.headerBackground, color: props.textColour}} onClick={props.onClick}>{props.name}</button>
      </div>
    </div>
  )
}

/*
List of props passed to the Preferences dialog
*/
interface PreferencesProps
{
	showModal: any;
	setTheme: any;
	taskExecutor: any;
}

/*
The modal dialog for editing user preferences
*/
//const Preferences = ({showModal}:PreferencesProps) => {
const Preferences = (props:PreferencesProps) =>
{

	const { i18n, t } = useTranslation();

	//	-------------------------------------------------
	//
	//	apply new settings to the database for this user.
	//
	//	-------------------------------------------------
  
	function setTheme( args: { darkMode: boolean } )
	{
	
		// set the theme in the main application.
		props.setTheme( args.darkMode == true ? "dark" : "light" );
		
		// update the user-preferences database.
		const settings: UserSettings = { darkMode: args.darkMode };
		const currentTask: CurrentTask =	{
							taskType: TaskType.UPDATE_SETTINGS,
							settings: settings
							}
		props.taskExecutor( { currentTask: currentTask } );

	} // setTheme

  return (
    <>
      <div className="background" />
      <div className="centered">      
        <div className="preferences">
            <div className="preferences-header">
              <div className="header-text">{t("Preferences")}</div>
            </div>
            <div className="preferences-body" style={{width: "100%"}}>
	      <Collapsible open header={t("Theme")}>
	        <div style={{display: "flex"}}>
		  <ThemeCard name={t("Dark")} textColour="white" headerBackground="#e70068" bodyBackground="black" onClick={() => setTheme( { darkMode: true } )}/>
		  <ThemeCard name={t("Light")} textColour="black" headerBackground="#e70068" bodyBackground="white" onClick={() => setTheme( { darkMode: false } )}/>
		</div>
	      </Collapsible>
	    </div>
	    <div className="preferences-footer">
	      <button className="ok-button" onClick={() => props.showModal(false)}>OK</button>
	    </div>
          </div>
      </div>
    </>
  )
  
} // Preferences

export default Preferences
