/*
This code is modified from
https://.com/edonec/build-a-react-collapsible-component-from-scratch-using-react-hooks-typescript-73dfd02c9208
*/

import "./collapsible.css"

import React, { useEffect, useRef, useState } from "react";
interface IProps {
  open?: boolean;
  children: React.ReactNode;
  header: string | React.ReactNode;
  headerClassName?: string;
  titleClassName?: string;
  iconButtonClassName?: string;
  contentClassName?: string;
  contentContainerClassName?: string;
  collapsibleClassName?: string;
}

const Collapsible: React.FC<IProps> = ({
  open,
  collapsibleClassName = "collapsible-card",
  headerClassName = "collapsible-header",
  titleClassName = "title-text",
  iconButtonClassName = "collapsible-icon-button",
  contentClassName = "collapsible-content",
  contentContainerClassName = "collapsible-content-padding",
  children,
  header
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>(
    open ? undefined : 0
  );
  const ref = useRef<HTMLDivElement>(null);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };
  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    // @ts-ignore
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);
  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);
  return (
    <>
      <div className={collapsibleClassName}>
        <div>
          <div className={headerClassName}>
            <div className={titleClassName}>{header}</div>
            <button
              type="button"
              className={iconButtonClassName}
              onClick={handleFilterOpening}
            >
              <i
                className={`fas fa-chevron-down ${
                  isOpen
                    ? "rotate-center down"
                    : "rotate-center up"
                }`}
              />
            </button>
          </div>
        </div>
        <div className={contentClassName} style={{ height }}>
          <div ref={ref}>
            <div className={contentContainerClassName}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;
