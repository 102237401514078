import React from 'react';
import '../App.css';
import './search-compute.css';
import CSS from 'csstype';
import { AccessToken } from '../types';
import { withTranslation, WithTranslation } from "react-i18next";

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface SiteListProps extends WithTranslation
{

	changeSite: any;
	placeholderShown: boolean;
	siteCapabilitiesToken: AccessToken;

} // SiteListProps

interface SiteListState
{

	siteList: string[];

} // SiteListState

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

class SearchComputeSiteList extends React.Component<SiteListProps, SiteListState>
{

	constructor( props:SiteListProps )
	{
		super( props );
		this.state =
		{
			siteList: []
		};
	};

	async componentDidMount()
	{

		// only proceed if we have a site-capabilities access token.
		if (this.props.siteCapabilitiesToken.access_token !== "")
		{
							
			//var urlCommand: string =	'http://localhost:8080/v1/site_capabilities/list_sites?';
			var urlCommand: string =	'https://gateway.srcdev.skao.int/tannet-api/v1/site_capabilities/list_sites?';
			
			// add site-capabilities access token.
			urlCommand = urlCommand +	'token=' + this.props.siteCapabilitiesToken.access_token;

			try
			{
			
				const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}} );
				
				console.log( "search-compute-site-list => componentDidMount => apiResult.status:" );
				console.log( apiResult.status );

				// Return code 200 means the API has run.
				if (apiResult.status === 200)
				{

					const returnedJson = await apiResult.json();

					// get services list.
					var siteList: any = [];
					if (returnedJson.sites !== undefined)
						siteList = returnedJson.sites;

					// update the state with the list of returned sites.
					this.setState( {siteList: siteList} );
				
				}
				
			}
			catch (e)
			{
			}
		
		}
		
	} // componentDidMount

	//	------------------------------------------------------------
	//
	//	Handler for select box onChange event.
	//
	//	------------------------------------------------------------
	
	onChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const value = event.target.value;
		
		// raise an onChange event.
		this.props.changeSite( {site: value} );
				
	} // onChange
	
	render()
	{
	
		{/* main search form */}
		return	(
		    	
		    	<select	name = "lstSite"
		    			className = "site-listbox"
		    			multiple = {false}
		    			onChange = {this.onChange}
		    			data-placeholder-shown = {this.props.placeholderShown ? "T" : "F"}>
		    		<option hidden selected value = "">{this.props.t("Select site")}</option>
		    		<option label = "All" value = "all"> All </option>
		    		{
		    			this.state.siteList.map
		    			(
		    				item =>
		    				(
		    					<option label = {item} value = {item}> {item} </option>
		    				)
		    			)
		    		}
			</select> 
			
			)
			
	}

} // SearchComputeSiteList

export default withTranslation() (SearchComputeSiteList);
