import React, { useState, useRef, Ref, useEffect } from 'react';
import '../App.css';
import './home.css';
import CSS from 'csstype';

// images
import closeIcon from '../icons/close.254.png';
import srcLogo from '../icons/srcnet-dark.svg';
import srcLightLogo from '../icons/srcnet-light.svg';

// classes
import { useTranslation } from "react-i18next";

//	--------------------------------------------------------------------------
//
//	C O N S T A N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

enum IndicatorValue
{
	GREEN,
	RED,
	UNKNOWN,
	NONE
}

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function Home()
{

	// translation function
	const { t } = useTranslation();
	
	const SERVICES:	{
				id: string,
				level: number,
				service: string,
				indicator: boolean
				}[] =	[	{ id: 'iam', level: 0, service: 'IAM', indicator: true },
						{ id: 'rucio', level: 0, service: 'Rucio', indicator: true },
						{ id: 'apis', level: 0, service: 'APIs', indicator: false },
						{ id: 'auth', level: 1, service: 'Authentication API', indicator: true },
						{ id: 'site', level: 1, service: 'Site-capabilities API', indicator: true },
						{ id: 'data', level: 1, service: 'Data-management API', indicator: true },
						{ id: 'gateway', level: 1, service: 'Gateway-backend API', indicator: true },
						{ id: 'permission', level: 1, service: 'Permissions API', indicator: true } ];
						
	// statuses for each service are placed in this state variable.
  	/*const [sStatus, setStatus] = useState< { id: string, status: IndicatorValue }[] >(	[
  												{ id: 'iam', status: IndicatorValue.UNKNOWN },
  												{ id: 'rucio', status: IndicatorValue.UNKNOWN },
  												{ id: 'auth', status: IndicatorValue.UNKNOWN },
  												{ id: 'site', status: IndicatorValue.UNKNOWN },
  												{ id: 'data', status: IndicatorValue.UNKNOWN },
  												{ id: 'gateway', status: IndicatorValue.UNKNOWN },
  												{ id: 'permission', status: IndicatorValue.UNKNOWN }
  												] );*/
  	const [sIAMStatus, setIAMStatus] = useState< IndicatorValue >( IndicatorValue.UNKNOWN );
  	const [sRucioStatus, setRucioStatus] = useState< IndicatorValue >( IndicatorValue.UNKNOWN );
  	const [sAuthStatus, setAuthStatus] = useState< IndicatorValue >( IndicatorValue.UNKNOWN );
  	const [sSiteStatus, setSiteStatus] = useState< IndicatorValue >( IndicatorValue.UNKNOWN );
  	const [sDataStatus, setDataStatus] = useState< IndicatorValue >( IndicatorValue.UNKNOWN );
  	const [sGatewayStatus, setGatewayStatus] = useState< IndicatorValue >( IndicatorValue.UNKNOWN );
  	const [sPermissionStatus, setPermissionStatus] = useState< IndicatorValue >( IndicatorValue.UNKNOWN );

	//	------------------------------------------------------------
	//
	//	An asynchronous function that resolves a name against
	//	an online database, such as NED, SIMBAD or Sesame.
	//
	//	------------------------------------------------------------
  	
  	async function getServiceStatus( args: { id: string } )
  	{
	
		try
		{
		
			//const apiResult = await fetch( 'http://localhost:8080/v1/get_service_status?service=' + args.id.toLowerCase() );
			const apiResult = await fetch( 'https://gateway.srcdev.skao.int/tannet-api/v1/get_service_status?service=' + args.id.toLowerCase() );
			
			if (apiResult.status === 200)
			{
				
				const returnedJson = await apiResult.json();
				
				// get the status colour.
				var statusColour: IndicatorValue = IndicatorValue.UNKNOWN;
				if (returnedJson.service_status.toUpperCase() === 'RUNNING')
					statusColour = IndicatorValue.GREEN;
				if (returnedJson.service_status.toUpperCase() === 'STOPPED')
					statusColour = IndicatorValue.RED;
				
				// add a new element.
				//var newStatus:  { id: string, status: IndicatorValue } =	{
				//								id: returnedJson.service_id.toLowerCase(),
				//								status: statusColour
				//								};
				
				// update the state.
				//setStatus( prevStatus => [ ...prevStatus, newStatus ] );
				switch (returnedJson.service_id.toLowerCase())
				{
					case 'iam':
						setIAMStatus( statusColour );
						break;
					case 'rucio':
						setRucioStatus( statusColour );
						break;
					case 'auth':
						setAuthStatus( statusColour );
						break;
					case 'site':
						setSiteStatus( statusColour );
						break;
					case 'data':
						setDataStatus( statusColour );
						break;
					case 'gateway':
						setGatewayStatus( statusColour );
						break;
					case 'permission':
						setPermissionStatus( statusColour );
						break;
				}
				
				console.log( "id: " + args.id + ", status: " + returnedJson.service_status );
			
			}
			
      		}
      		catch (e)
      		{
			console.log(e);
		}
  	
  	} // getServiceStatus
  	
  	// this code will not be executed immediately, it will be execute a short while after the page has loaded.
  	useEffect	( () =>
		  	{
			
				// check the status of various services that the backend uses.
				getServiceStatus( { id: 'iam' } );
				getServiceStatus( { id: 'rucio' } );
				getServiceStatus( { id: 'auth' } );
				getServiceStatus( { id: 'site' } );
				getServiceStatus( { id: 'data' } );
				getServiceStatus( { id: 'gateway' } );
				getServiceStatus( { id: 'permission' } );
				
			}, []
			);
					

	//	--------------------------------------------------------------------------
	//
	//	Display a row, with some indent (0, 1, 2, ...), with a coloured
	//	service-status indicator.
	//
	//	--------------------------------------------------------------------------
	
	function indicatorRow( args:	{
					id: string,
					level: number,
					service: string,
					indicator: boolean,
					status: IndicatorValue
					} )
	{
	
		const xOffset: number = args.level * 35;
		
		//console.log( "service-status:" );
		//console.log( sStatus );
		
		// get status
		var status: IndicatorValue = IndicatorValue.NONE;
		if (args.indicator === true)
		{
			status = IndicatorValue.UNKNOWN;
			//var statusList: { id: string, status: IndicatorValue }[] = sStatus.reverse();
			//const index: number = statusList.findIndex( element => element.id === args.id );
			//if (index > -1)
			//	status = sStatus[ index ].status;
			switch (args.id)
			{
				case "iam":
					status = sIAMStatus;
					break;
				case "rucio":
					status = sRucioStatus;
					break;
				case "auth":
					status = sAuthStatus;
					break;
				case "site":
					status = sSiteStatus;
					break;
				case "data":
					status = sDataStatus;
					break;
				case "gateway":
					status = sGatewayStatus;
					break;
				case "permission":
					status = sPermissionStatus;
					break;
			}
		}
	
		// default to none, and set the colour depending on the status.
		var indicatorColour = 'N';
		switch (status)
		{
			case IndicatorValue.GREEN:
				indicatorColour = 'G';
				break;
			case IndicatorValue.RED:
				indicatorColour = 'R';
				break;
			case IndicatorValue.UNKNOWN:
				indicatorColour = 'U';
				break;
		}
	
		return	(
			
			<div	style =	{{
						flex: '0 0 auto',
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center'
						}} >
	    			<div	style =	{{
	    						flex: '0 0 ' + xOffset.toString() + 'px'
	    						}} />
	    			<div	className = "home-service-status-detail" >{args.service}</div>
	    			<div	style =	{{
	    						flex: '1 1'
	    						}} />
	    			<div	className = "home-service-status-indicator"
	    				data-value = {indicatorColour} >{status === IndicatorValue.UNKNOWN ? '?' : ''}</div>
	    			<div	style =	{{
	    						flex: '0 0 15px'
	    						}} />
			</div>
    				
    			)
	
	} // indicatorRow

	//	-------------------------------------------------
	//
	//	html
	//
	//	-------------------------------------------------

	return	(
			    	
	    	<div className = "home">
	    		<div	className = "home-main">
	    			<div	style =	{{
	    						flex: '1 1'
	    						}} />
				<div className = "home-ska-logo-container" data-dark="T"><img src = {srcLogo} alt = "" width = "350px" /></div>
				<div className = "home-ska-logo-container" data-dark="F"><img src = {srcLightLogo} alt = "" width = "350px" /></div>
	    			<div	style =	{{
	    						flex: '1 1'
	    						}} />
	    		</div>
    			<div	style =	{{
    						flex: '0 0 15px'
    						}} />
    			<div	className = "home-service-right-panel">
    				<div	className = "home-service-top-right-panel">
    				</div>
	    			<div	style =	{{
	    						flex: '0 0 15px'
	    						}} />
		    		<div	className = "home-service-status">
		    			<div	style =	{{
		    						flex: '0 0 10px'
		    						}} />
		    			<h2	className = "home-service-status-title">Service Status</h2>
		    			<div	style =	{{
		    						flex: '0 0 10px'
		    						}} />
	    				{
		    				SERVICES.map
	    					(
							(item, index) =>
							(
								indicatorRow(	{
										id: item.id,
										level: item.level,
										service: item.service,
										indicator: item.indicator,
										status: item.indicator ? IndicatorValue.GREEN : IndicatorValue.NONE
										} )
							)
	    					)
	    				}
		    			<div	style =	{{
		    						flex: '0 0 10px'
		    						}} />
		    		</div>
	    		</div>
	    	</div>
			    	
		)

} // Home
