import React from 'react';
import '../App.css';
import './user-control.css';
import CSS from 'csstype';

// images

// classes
import { AccessToken } from '../types';

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface UserControlOIDCTokenProps
{

	authToken: AccessToken;
	siteCapabilitiesToken: AccessToken;
	dataManagementToken: AccessToken;
	gatewayBackendToken: AccessToken;

} // UserControlOIDCTokenProps

interface UserControlOIDCTokenState
{

} // UserControlOIDCTokenState

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default class UserControlOIDCToken extends React.Component<UserControlOIDCTokenProps, UserControlOIDCTokenState>
{

	constructor( props:UserControlOIDCTokenProps )
	{
		super( props );
	};

	//	------------------------------------------------------------
	//
	//	Get the logged in user details.
	//
	//	------------------------------------------------------------

/*	async componentDidMount()
	{
		
		try
		{	
	
			//const apiResult = await fetch( 'https://sdc-dev.astron.nl/esap-api/accounts/user-profiles/', { method: 'GET', credentials: 'include' } );
			const apiResult = await fetch( 'https://gateway.srcdev.skao.int/esap-api/accounts/user-profiles/', { method: 'GET', credentials: 'include' } );
			const response = await apiResult.json();
			console.log("results: " + response.results.length + ", count: " + response.count);
			
			// are there any results returned?
			if (response.results.length > 0)
			{
			
				let username: string = response.results[0].user_name;					
				let fullName: string = response.results[0].full_name;
				let email: string = response.results[0].user_email;
				let oidcIDToken = response.results[0].oidc_id_token;
				let oidcAccessToken = response.results[0].oidc_access_token;
				let idTokenExpiration = response.results[0].id_token_expiration;
				this.setState( {username: username, fullName: fullName, email: email, oidcIDToken: oidcIDToken, oidcAccessToken: oidcAccessToken, idTokenExpiration: idTokenExpiration} );
			
			}
			else
			{
				console.log("no results found");
			}
				
      		}
      		catch (e)
      		{
			console.log(e);
		}
		
	} // componentDidMount*/

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	render()
	{
	
		return	(
			<div className="user-oidc-container">
				<table className="user-oidc">
					<tbody>
						<tr className="user-oidc-row">
							<td className="user-oidc-cell"><b>Auth access token:</b></td>
							<td className="user-oidc-cell">{this.props.authToken.access_token}</td>
						</tr>
						<tr className="user-oidc-row">
							<td className="user-oidc-cell"><b>Auth refresh token:</b></td>
							<td className="user-oidc-cell">{this.props.authToken.refresh_token}</td>
						</tr>
						<tr className="user-oidc-row">
							<td className="user-oidc-cell"><b>Site capabilities access token:</b></td>
							<td className="user-oidc-cell">{this.props.siteCapabilitiesToken.access_token}</td>
						</tr>
						<tr className="user-oidc-row">
							<td className="user-oidc-cell"><b>Site capabilities refresh token:</b></td>
							<td className="user-oidc-cell">{this.props.siteCapabilitiesToken.refresh_token}</td>
						</tr>
						<tr className="user-oidc-row">
							<td className="user-oidc-cell"><b>Data management access token:</b></td>
							<td className="user-oidc-cell">{this.props.dataManagementToken.access_token}</td>
						</tr>
						<tr className="user-oidc-row">
							<td className="user-oidc-cell"><b>Data management refresh token:</b></td>
							<td className="user-oidc-cell">{this.props.dataManagementToken.refresh_token}</td>
						</tr>
						<tr className="user-oidc-row">
							<td className="user-oidc-cell"><b>Gateway-backend access token:</b></td>
							<td className="user-oidc-cell">{this.props.gatewayBackendToken.access_token}</td>
						</tr>
						<tr className="user-oidc-row">
							<td className="user-oidc-cell"><b>Gateway-backend refresh token:</b></td>
							<td className="user-oidc-cell">{this.props.gatewayBackendToken.refresh_token}</td>
						</tr>
					</tbody>
				</table>
			</div>
			)
		
	}

} // UserControlOIDCToken
