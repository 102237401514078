import React, { useState } from 'react';
import '../App.css';
import './tool.css';
import CSS from 'csstype';

// images

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface ToolProps
{

	name: string;
	icon: string;
	text: string;
	onClick: any;
	selected?: boolean;
	disabled?: boolean;

} // ToolProps

interface ToolState
{

} // ToolState

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default class Tool extends React.Component<ToolProps, ToolState>
{

	constructor( props:ToolProps )
	{
		super( props );
	};

	//	------------------------------------------------------------
	//
	//	A non-highlighted tool button.
	//
	//	------------------------------------------------------------

	UnselectedTool( args:ToolProps )
	{

		if (args.disabled === true)
			return	(
			
				<div className="button-tabbed-container">
					<button name={args.name} type="button" className="button-tabbed" data-selected="F"><div className="tool-icon-container"><img className="opaque-image" src={args.icon} alt="" width="22" height="22" /></div><div className="button-text-container" data-disabled="T"><div className="text-container-inner">{args.text}</div></div></button>
				</div>
			
				)
		else
			return	(
			
				<div className="button-tabbed-container">
					<button name={args.name} type="button" className="button-tabbed" data-selected="F" onClick={args.onClick}><div className="tool-icon-container"><img src={args.icon} alt="" width="22" height="22" /></div><div className="button-text-container"><div className="text-container-inner">{args.text}</div></div></button>
				</div>
			
				)

	} // UnselectedTool

	//	------------------------------------------------------------
	//
	//	A highlighted tool button.
	//
	//	------------------------------------------------------------

	SelectedTool( args:ToolProps )
	{

		return	(
		
			<div className="button-tabbed-container">
				<button name={args.name} type="button" className="button-tabbed" data-selected="T" onClick={args.onClick}><div className="tool-icon-container"><img src={args.icon} alt="" width="22" height="22" /></div><div className="button-text-container"><div className="text-container-inner">{args.text}</div></div></button>
			</div>
		
			)

	} // SelectedTool

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	render()
	{

		if (this.props.selected === true)
			return	(
				<>{this.SelectedTool( {name: this.props.name, icon: this.props.icon, text: this.props.text, onClick: this.props.onClick} )}</>
				)
		else
			return	(
				<>{this.UnselectedTool( {name: this.props.name, icon: this.props.icon, text: this.props.text, onClick: this.props.onClick, disabled: this.props.disabled} )}</>
				)
			
	}

} // Tool
